import { createGeoJSON, GeoJSON } from '../../pages/dashboard/map/utils'
import { groupBy } from "lodash";
import { DateTime } from "luxon";
import Target from '../../utils/Target';
import { dateTimeToEpoch } from '@modul-connect/shared/utils/dateTimeUtils';
import { getOverweightLogsId } from './app';
import { dateTimeToStringKey } from '../../utils/vehicleUtils';
import { duration } from 'moment';

const getEarliestTarget = targets => {
  let smallest = targets[0]
  for (const target of targets) {
    if (target.timestamp < smallest.timestamp)
      smallest = target
  }
  return smallest
}

function pushGeoLogsToAnimation(vehicles, geoLogs) {
  if (geoLogs.length === 0) return
  const indexMap = new Map()
  geoLogs.forEach(geoLog => {
    let index = indexMap.get(geoLog.boxId)

    if (!(index || index === 0) || index === -1) {
      index = vehicles.findIndex(x => x.boxId === geoLog.boxId)
      indexMap.set(geoLog.boxId, index)
    }
    if (!(index || index === 0) || index === -1) return

    let vehicle = vehicles[index]
    if (vehicle?.isPrivate) return
    if(!vehicle) {
      vehicle = {targets: []}
      vehicles[index] = vehicle
    }

    const newTS = DateTime.fromISO(geoLog.timestamp).toSeconds()
    if (!vehicle.targets.some(t => t.timestamp === newTS)) {
      vehicle.targets.push(
        new Target(
          newTS, geoLog.speed,
          [geoLog.longitude, geoLog.latitude], "FETCHED_VEHICLE_POSITIONS",
        )
      )
    }
  })

  vehicles.forEach(vehicle => {
    if (!vehicle.targets?.length) return
    vehicle.geoJSON = {
      ...vehicle.geoJSON,
      position: {
        ...vehicle.geoJSON?.position,
        geometry: {
          ...vehicle.geoJSON?.position?.geometry,
          coordinates: getEarliestTarget(vehicle.targets).coordinates
        }
      }
    }
  })
}

const updateDataItem = (existingData, newDataItem, newDataTimestamp) => {
  const relatedExistingData = existingData?.find(eD => eD.id === newDataItem.id)
  if (relatedExistingData) {
    if (DateTime.fromISO(relatedExistingData.timestamp)?.toSeconds() > newDataTimestamp) {
      return relatedExistingData
    }
  }
  return {
    ...newDataItem,
    timestamp: DateTime.fromSeconds(newDataTimestamp).toISO()
  }
}

const getUpdatedStatus = (status, existingData) => {
  const newData = status.map(d => {
    return updateDataItem(existingData, d.data, d.epoch)
  })

  const entriesWithoutUpdate = existingData?.filter(dataEntry => !newData.some(newDataEntry => newDataEntry.id === dataEntry.id))

  return {
    data: [
      ...entriesWithoutUpdate,
      ...newData
    ]
  }
}

function updateStatus(vehicle, status, type) { 
  switch (type) {
    case 'battery': {
      const newBatteries = getUpdatedStatus(status, vehicle.currentStatus.batteries)

      vehicle.currentStatus.batteries = newBatteries.data
      break
    }
    case 'thermometers': {
      const newThermometers = getUpdatedStatus(status, vehicle.currentStatus.thermometers)
      vehicle.currentStatus.thermometers = newThermometers.data
      break
    }
    case 'humidity': {
      const newHumidity = getUpdatedStatus(status, vehicle.currentStatus.humiditySensors)
      vehicle.currentStatus.humiditySensors = newHumidity.data
      break
    }
    case 'weight': {
      const newStatus = getLastDataPointFromBulk(status)

      if (newStatus.data.gross > 0) {
        vehicle.currentStatus.weightSystem = newStatus.data
      }
      break
    }
    case 'lps': {
      const newStatus = getLastDataPointFromBulk(status)
      vehicle.currentStatus.lithiumPowerSupply = newStatus.data
      break
    }
  }
}

function updatePositions(vehicle, positions) {
  if (!vehicle.currentStatus.timestampEpoch || positions.epoch > vehicle.currentStatus.timestampEpoch){
    vehicle.currentStatus.timestampEpoch = positions.epoch
    vehicle.currentStatus.timeStamp = DateTime.fromMillis(positions.epoch * 1000).toISO()
    vehicle.currentStatus.geoLocation = positions.data
  }
}

function addRoute(x, drivingLogs, date) {
  if (!x.geoJSON?.routes) {
    x.geoJSON = {
      ...x.geoJSON,
      routes: {}
    }
  }
  x.geoJSON.routes[date] = {}
  const routes = x.geoJSON.routes[date]

  let privateIndex = 0
  for (let log of drivingLogs) {

    let index = log.startTime ?? ('private' + privateIndex)
    if (!log.startTime) privateIndex++

    if (routes[index]) continue
    routes[index] = GeoJSON([], { geometry: 'LineString'})


    const route = routes[index]
    route.geometry.coordinates = []
    route.geometry.velocities = []
    route.endTime = log.endTime
    route.distance = log.distance
    route.driver = log.driver
    route.driverId = log.driverId
    route.purpose = log.purpose
    route.isPrivate = log.isPrivate // refers to whether the portal user is allowed to see it, not necessarily same as purpose tag

    if(log.logs && log.logs.length) {
      log.logs.forEach(r => {
        route.geometry.coordinates.push([r.longitude, r.latitude])
        route.geometry.velocities.push(r.speed)
      })
    }
  }

  return x
}

export const selectedVehicle = (state = {}, action) => {
  switch (action.type) {
    case 'VEHICLE_SELECTED':
      return state.boxId !== action.boxId || !action.deselectIfAlreadySelected
      ?{
        ...state,
        boxId: action.boxId,
        shouldScroll: action.shouldScroll,
        zoomToRoutes: action.zoomToRoutes,
        zoomToVehicle: action.zoomToVehicle
      }
      : {
        ...state,
        boxId: null,
        shouldScroll: false,
        zoomToRoutes: false,
        zoomToVehicle: false
      }
    case 'ROUTE_SELECTED':
      return action.boxId === null ?
      state
      :
      {
        ...state,
        boxId: action.boxId,
        shouldScroll: action.shouldScroll
      }
    default:
      return state;
  }
}

export const selectedAssetTracker = (state = null, action) => {
  switch (action.type) {
    case 'ASSET_TRACKER_SELECTED': {
      return {
        ...state,
        boxId: action.boxId,
        beaconId: action.beaconId
      }
    }
    case 'VEHICLE_SELECTED':
    case 'ROUTE_SELECTED': 
      return action.boxId ? null : state
    case 'ASSET_TRACKER_DESELECTED': 
      return null
    default: return state
  }
}

export const selectedSecurityAlarm = (state = null, action) =>{
  switch(action.type){
    case 'SECURITY_ALARM_INCIDENT_SELECTED':
      return{
        ...state,
        alarmId: action.alarmId,
        boxId: action.boxId,
        longitude: action.longitude,
        latitude: action.latitude
      }
    case 'SECURITY_ALARM_INCIDENT_UNSELECTED':
    case 'VEHICLE_SELECTED':
    case 'SECURITY_REPORTS_SUMMARY_FETCHED':
      return null
    default:
      return state;
  }
}

export const selectedRoute = (state = null, action) => {
  switch (action.type) {
    case 'ROUTE_SELECTED':
      return {
          ...state,
          boxId: action.boxId,
          endTime: action.endTime
        }
    case 'ROUTE_UNSELECTED':
      return null
    case 'VEHICLE_SELECTED':
      return state?.boxId === action.boxId 
      ? state
      : {
        ...state,
        boxId: null,
        endTime: null,
        shouldScroll: action.shouldScroll,
      }
    default:
      return state;
  }
}

export const battery_logs = (state = [], action) => {
  switch (action.type) {
    case 'VEHICLE_BATTERY_LOGS_FETCHED':
      {
        return action.data
      }
    case 'FETCHING_VEHICLE_BATTERY_LOGS_FAILED':
      {
        return null
      }
    default:
      return state
  }
}

export const lps_logs = (state = [], action) => {
  switch (action.type) {
    case 'LPS_BATTERY_LOGS_FETCHED':
      {
        return action.data
      }
    case 'FETCHING_LPS_BATTERY_LOGS_FAILED':
      {
        return null
      }
    default:
      return state
  }
}

export const lps_footprint = (state = [], action) => {
  switch (action.type) {
    case 'LPS_FOOTPRINT_FETCHED':
      {
        return action.data ?? null
      }
    case 'FETCHING_LPS_FOOTPRINT_FAILED':
      {
        return null
      }
    default:
      return state
  }
}

export const temperature_logs = ( state = [], action) => {
  switch(action.type){
    case 'VEHICLE_TEMPERATURE_LOGS_FETCHED':
      {
        return action.data
      }
    case 'FETCHING_VEHICLE_TEMPERATURE_LOGS_FAILED':
      {
        return null
      }
    default:
      return state
  }
}

export const humidity_logs = ( state = [], action) => {
  switch(action.type){
    case 'VEHICLE_HUMIDITY_LOGS_FETCHED':
      {
        return action.data
      }
    case 'FETCHING_VEHICLE_HUMIDITY_LOGS_FAILED':
      {
        return null
      }
    default:
      return state
  }
}

export const weight_logs = (state = {}, action) => {
  switch (action.type) {
    case 'VEHICLE_WEIGHTS_FETCHED':
      for (const log of action.data)
        state[log.boxId] = [
          ...(state[log.boxId] ?? []).filter((value, index, self) =>index === self.findIndex((t) => ( t.timestamp === value.timestamp))), 
          {
            ...log, 
            samplingInterval: action.samplingInterval
          }]
      if (action.data.length === 0 && action.boxId) state[action.boxId] = null
      return {...state}
    case 'FETCHING_VEHICLE_WEIGHTS_FAILED':
      if (action.boxId)
        state[action.boxId] = undefined
      else
        return {}
    default:
      return state
  }
}

export const weight_report_summary = (state = {}, action) => {
  switch (action.type) {
    case 'OVERWEIGHT_REPORT_SUMMARIES_FETCHED':
      return action.data
    case 'FETCHING_OVERWEIGHT_REPORT_SUMMARIES_FAILED':
      return {}
    default:
      return state;
  }
}

export const vehicles = (state = [], action) => {
  let vehicle = null
  switch (action.type) {
    case 'VEHICLES_FETCHED':
      return action.data.map((v) => {
        const prevVehicle = state.find(x => x.boxId === v.boxId);
        let today_yymmdd = DateTime.local().toISODate()
        const geoJSON = createGeoJSON(v, today_yymmdd)
        if (prevVehicle?.geoJSON?.routes) geoJSON.routes = prevVehicle.geoJSON.routes
        return {
          ...prevVehicle,
          ...v,
          name: v.vehicleName ?? v.vehicle.baseVehicleName,
          geoJSON: geoJSON,
          targets: [],
          purposeTag: v.purposeTag,
          isPrivate: v.purposeTag === 'private' && !v.currentStatus?.geoLocation
          // date: today_yymmdd
        }
      })
      case 'VEHICLE_FETCHED': {
        let updatedVehicles = [...state]
        const index = state.findIndex(x => x.boxId === action.data.mainbox_id);
        if (index >= 0) {
          let prevVehicle = updatedVehicles[index]
          updatedVehicles[index] = {
            ...prevVehicle,
            ...action.data,
          }
        }
        else {
          updatedVehicles.push({
            ...action.data,
          })
        }
        return updatedVehicles
      }

    // TELEMETRY -- SHOULD NOT CAUSE REACT COMPONENTS TO UPDATE
      case 'VEHICLE_STATUS_UPDATED':
        vehicle = state.find(x => x.boxId === action.status.boxId.split('/').pop())
        if (vehicle) {
          updateStatus(vehicle, action.status.data)
          /* if (action.status.data.geoLocation && !vehicle.targets.some(t => t.timestamp === action.status.data.timestampEpoch)) {
            vehicle.targets.push(
              new Target(
                action.status.data.timestampEpoch, action.status.data.geoLocation.speed, 
                [action.status.data.geoLocation.longitude, action.status.data.geoLocation.latitude]
              )
            )
          } */
        }
        return state
      
      case 'VEHICLE_BATTERIES_UPDATED':
      case 'VEHICLE_TEMPERATURES_UPDATED':
      case 'VEHICLE_HUMIDITY_UPDATED':
      case 'VEHICLE_WEIGHT_UPDATED':
      case 'VEHICLE_LPS_STATUS_UPDATED':
        vehicle = state.find(x => x.boxId === action.status.boxId.split('/').pop())
          if (vehicle) {
            updateStatus(
              vehicle,
              action.status.data,
              action.type === "VEHICLE_TEMPERATURES_UPDATED" ? 'thermometers' :
                action.type === "VEHICLE_BATTERIES_UPDATED" ? 'battery' :
                action.type === "VEHICLE_WEIGHT_UPDATED" ? 'weight' :
                action.type === "VEHICLE_LPS_STATUS_UPDATED" ? 'lps' :
                'humidity'
            )
          }
        return state


      case 'VEHICLE_POSITIONS_UPDATED':
        vehicle = state.find(x => x.boxId === action.status.boxId.split('/').pop())
        if (vehicle) {
          const lastDataPoint = getLastDataPointFromBulk(action.status.data)

          if (!lastDataPoint) return state

          const sortedDataPoints = action.status.data?.sort((a, b) => a.epoch > b.epoch ? 1 : -1)

          updatePositions(vehicle, lastDataPoint)
          if (!vehicle.targets.some(t => t.timestamp >= lastDataPoint.epoch)) {
            vehicle.targets = vehicle.targets.concat(
              sortedDataPoints.map(point => {
                return new Target(
                  point.epoch, point.data.speed,
                  [point.data.longitude, point.data.latitude]
                )
              })
            )
          }
        }
        return state

      case 'FETCHED_VEHICLE_POSITIONS':
        pushGeoLogsToAnimation(state, action.data)
        return state
    
    // TELEMETRY -- SHOULD NOT CAUSE REACT COMPONENTS TO UPDATE

    case 'FETCHED_LAST_KNOWN_POSITIONS':
      const timestamp_lastKnownPosition = dateTimeToStringKey(action.end)

      let updated_vehicles = JSON.parse(JSON.stringify(state))
      Object.keys(action.data).map(logBoxId => {
        const index = updated_vehicles.findIndex(v => v.boxId === logBoxId)
        if (index < 0) return state

        let updatedLastKnownPosition = updated_vehicles[index].lastKnownPosition ?? {}
        updatedLastKnownPosition[timestamp_lastKnownPosition] = action.data[logBoxId]

        updated_vehicles[index] = {
          ...updated_vehicles[index],
          lastKnownPosition: updatedLastKnownPosition
        }
      })

      return updated_vehicles


    case 'VEHICLE_ADDED':
      console.warn('ERROR: This vehicle was missing in getVehicles', action.vehicle)
      return state

    case 'FETCHED_VEHICLE_ROUTES':
    case 'FETCHED_VEHICLE_ROUTES_DETAILED':
      let logs = action.data.filter(x => { return x.isPrivate || x.logs && x.logs.length !== 0  })

      logs = groupBy(logs, function(l) {
        return l.boxId;
      })

      let vehicles_updated = JSON.parse(JSON.stringify(state))
      vehicles_updated = vehicles_updated.map(x => {
        const boxlogs = logs[x.boxId];
        if (boxlogs) {
          x = {
            ...addRoute(x, boxlogs, action.start.toISODate()),
          }
        }
        else {
          x = {
            ...x,
            geoJSON: {
              ...x.geoJSON,
              routes: x.geoJSON?.routes ?? []
            }
          }
        }
        return x
      })

      return vehicles_updated

    case 'VEHICLES_RESET':
        return []
    case 'WARNING_LOGS_FETCHED':
      const timestamp_warningLogs = DateTime.fromSeconds(action.start).toISODate()
      return state.map(vehicle => {
        let updatedWarningLogs = vehicle.warning_logs ?? {}
        updatedWarningLogs[timestamp_warningLogs] = {
          batteryGuardWarnings: action.data?.batteryGuardWarnings ?? [],
          overweightWarnings: action.data?.overweightWarnings ?? [],
          humidityWarnings: action.data?.humidityWarnings ?? [],
          temperatureWarnings: action.data?.temperatureWarnings ?? []
        }
        
        vehicle.warning_logs = updatedWarningLogs
        return vehicle;
      })
    
      case 'CLEAR_ANIMATION_TARGETS':
        return state.map(vehicle => {
          vehicle.targets = []
          return vehicle
        })
      case 'SET_LICENSE_NUMBER': 
        return state.map(v => {
          if (v.boxId === action.boxId) {
            return {
              ...v,
              vehicle: {
                ...v.vehicle,
                oldLicenseNumber: v.vehicle.licenseNumber,
                licenseNumber: action.licenseNumber
              }
            }
          }
          else return v
        })
      case 'LICENSE_NUMBER_UPDATED':
        return state.map(v => {
          if (v.boxId === action.boxId) {
            return {
              ...v,
              vehicle: {
                ...v.vehicle,
                oldLicenseNumber: null
              }
            }
          }
          else return v
        })
      case 'LICENSE_NUMBER_UPDATE_FAILED':
        return state.map(v => {
          if (v.boxId === action.boxId) {
            return {
              ...v,
              vehicle: {
                ...v.vehicle,
                licenseNumber: v.vehicle.oldLicenseNumber,
                oldLicenseNumber: null
              }
            }
          }
          else return v
        })
      case 'CHANGE_VEHICLE_NAME': {
        return state.map(v => {
          if (v.boxId === action.vehicleId) {
            return {
              ...v,
              oldVehicleName: v.vehicleName,
              vehicleName: action.newName
            }
          }
          else return v
        })
      }
      case 'VEHICLE_NAME_UPDATED': {
        return state.map(v => {
            if (v.boxId === action.vehicleId) {
              return {
                ...v,
                oldVehicleName: null
                }
              }
            else return v
          })
      }
      case 'VEHICLE_NAME_UPDATE_FAILED': {
        return state.map(v => {
          if (v.boxId === action.vehicleId) {
            return {
              ...v,
              vehicleName: v.oldVehicleName,
              oldVehicleName: null
              }
            }
          else return v
        })
      }
    default:
      return state;
  }
}

const getLastDataPointFromBulk = (bulkData) => {
  if (bulkData?.length === 1) return bulkData[0]

  const dataPoints = bulkData?.sort((a, b) => a.epoch > b.epoch ? 1 : -1)
  return dataPoints?.length ? dataPoints[dataPoints.length - 1] : undefined
}

export const vehicle_animation_status = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_ANIMATION_SPEED': {
      return {
        ...state,
        [action.boxId]: {
          ...state[action.boxId],
          speed: action.speed
        }
      }
    }
    case 'VEHICLE_POSITIONS_UPDATED':
        // The telemetry doesn't necessarily arrive in the ascending order
        const lastDataPoint = getLastDataPointFromBulk(action.status.data)

        if (!lastDataPoint) return state

        const splitBoxIdStr = action.status.boxId?.split('/')
        const boxId = splitBoxIdStr?.length > 1 ? splitBoxIdStr[1] : action.status.boxId

        const epoch = state[boxId]?.timestampEpoch
        if (epoch && lastDataPoint.epoch < epoch)
          return state
        
        return {
          ...state,
          [boxId]: {
            ...state[boxId],
            timestampEpoch: lastDataPoint.epoch,
            speed: lastDataPoint.data?.speed

          }
        }
    default:
      return state
  }
}

export const vehicles_security_system_status = (state = [], action) =>{
  switch (action.type){
    case "FETCHING_VEHICLES_SECURITY_SYSTEM_STATUS_SUCCEEDED":
      return [...state, 
        ...action?.data];
    default: 
      return state
  }

} 

export const vehicles_sorted = (state = {data: [], total: 0}, action) => {
  switch (action.type) {
    case 'VEHICLES_FETCHED_SORTED':
      return {
        data: action.data.boxes,
        total: action.data.total
      }
    default:
      return state;
  }
}

export const overweight_report = (state = {}, action) => {
  switch (action.type) {
    case 'OVERWEIGHT_REPORT_FETCHED':
      return {
        data: action.data,
        start: action.start,
        end: action.end
      }
    case 'FETCHING_OVERWEIGHT_REPORT_FAILED':
      return {}
    default:
      return state;
  }
}

// TODO: replace the other one with this, once we update the weight report page to the new design (collapsible tables)
export const overweight_report_v2 = (state = {}, action) => {
  let reportId
  switch (action.type) {
    case 'OVERWEIGHT_REPORT_FETCHED':
      reportId = getOverweightLogsId(action.boxId, action.start, action.end)

      const updatedState = state
      updatedState[reportId] = {
        data: action.data,
        start: action.start,
        end: action.end
      }
      
      return {...updatedState}

    case 'FETCHING_OVERWEIGHT_REPORT_FAILED':
      reportId = getOverweightLogsId(action.boxId, action.start, action.end)
      return {
        ...state,
        [reportId]: null
      }
    default:
      return state;
  }
}

export const tyrePressure_report = (state = {}, action) => {
  switch (action.type) {
    case 'TYRE_PRESSURE_REPORT_FETCHED':
      return {
        data: action.data,
        start: action.start,
        end: action.end
      }
    case 'FETCHING_TYRE_PRESSURE_REPORT_FAILED':
      return {}
    default:
      return state;
  }
}

export const battery_report = (state = {}, action) => {
  switch (action.type) {
    case 'BATTERY_REPORT_FETCHED':
      return {
        ...state,
        id: action.boxId,
        data: action.data,
        start: action.start,
        end: action.end
      }
    case 'FETCHING_BATTERY_REPORT_FAILED':
      return {}
    default:
      return state;
  }
}

export const temperature_report = (state = {}, action) => {
  switch (action.type) {
    case 'TEMPERATURE_REPORT_FETCHED':
      return {
        ...state,
        id: action.boxId,
        data: action.data,
        start: action.start,
        end: action.end
      }
    case 'FETCHING_TEMPERATURE_REPORT_FAILED':
      return {}
    default:
      return state;
  }
}

export const humidity_report = (state = {}, action) => {
  switch (action.type) {
    case 'HUMIDITY_REPORT_FETCHED':
      return {
        ...state,
        id: action.boxId,
        data: action.data,
        start: action.start,
        end: action.end
      }
    case 'FETCHING_HUMIDITY_REPORT_FAILED':
      return {}
    default:
      return state;
  }
}

export const asset_tracker_report = (state = {}, action) => {
  switch (action.type) {
    case 'ASSET_TRACKER_REPORT_FETCHED':
      return {
        boxIds: action.boxIds,
        data: action.data,
        total: action.total,
        start: action.start,
        end: action.end,
      }
    case 'FETCHING_ASSET_TRACKER_REPORT_FAILED':
      return {}
    default:
      return state;
  }
}

export const lps_report = (state = {
  summary: null
}, action) => {
  switch (action.type) {
    case 'LPS_REPORT_SUMMARIES_FETCHED': {
      return {
        ...state,
        summary: action.data
      }
    }
    case 'FETCHING_LPS_REPORT_SUMMARIES_FAILED': {
      return {
        ...state,
        summary: []
      }
    }
    default:
      return state
  }
}

export const lps_charging_data = (state = {}, action) => {
  switch (action.type) {
    case 'LPS_CHARGING_DATA_FETCHED': {
      var newState = state
      newState[action.boxId] = action.data 
      return{
        ...newState
      }
    }
    case 'FETCHING_LPS_CHARGING_DATA_FAILED': {
      var newState = state
      delete newState[action.boxId]
      return {
        ...newState
      }
    }
    default:
      return state
  }
}

export const security_reports_summary = (state = {}, action) => {
  switch (action.type) {
    case 'SECURITY_REPORTS_SUMMARY_FETCHED':
      const summary = []
      action.data.map(d => summary.push({
        vehicle: d.vehicleName,
        incidents: d.numberOfIncidents,
        vin: d.vin,
        mainbox_id: d.mainboxId
      }))
      return{
         ...state,
         summary: summary
       } 
    default:
      return state
  }
}

export const security_reports_detailed = (state = {}, action) => {
  switch (action.type) {
    case 'VEHICLES_SECURITY_REPORTS_FETCHED':
      if(!action.data) return state
      let updatedVehiclesReport = state.vehicles_reports
      if(!updatedVehiclesReport)
        updatedVehiclesReport = action.data
      else
        updatedVehiclesReport[action.vin] = action.data[action.vin]
      return {
        ...state,
        vehicles_reports: {...updatedVehiclesReport}
      }
    case 'FETCHING_VEHICLES_SECURITY_REPORTS_FAILED':
    default:
      return state
  }
}

export const driving_logs = (state = {}, action) => {
  switch (action.type) {
    case 'DRIVING_LOGS_FETCHED':
      const fetchedBoxId = action.boxId
      const fetchedDriverId = action.driverId
      if (!fetchedDriverId && !fetchedBoxId?.length) return state

      const updatedState = state
      if (fetchedBoxId) {
        updatedState[fetchedBoxId] = action.data?.filter(log => log.boxId === fetchedBoxId)
      }
      else if (fetchedDriverId) {
        if (fetchedDriverId === 'unknown') updatedState[fetchedDriverId] = action.data?.filter(log => !log.driverId)
        else updatedState[fetchedDriverId] = action.data?.filter(log => log.driverId === fetchedDriverId)
      }

      return {...updatedState}
    case 'FETCHING_DRIVING_LOGS_FAILED':
      return {
        ...state,
        [action.boxId ?? action.driverId]: null
      }
    case 'DRIVING_LOG_NOTE_UPDATED':
    case 'NOTE_ADDED_TO_LOG':
    case 'NOTE_REMOVED_FROM_LOG':
      if (!Object.keys(state)?.length) return state

      const changedState = {...state}
      if (changedState[action.boxId ?? action.driverId]?.length){
        const logIdParts = action.logId?.split('_')
        const startEpoch = logIdParts?.length ? parseInt(logIdParts[logIdParts.length - 1]) : undefined

        const relevantLogs = changedState[action.boxId ?? action.driverId]

        const logIndex = relevantLogs.findIndex(log => {
          return dateTimeToEpoch(DateTime.fromISO(log.startTime)) === startEpoch
        })
        if (logIndex > -1) changedState[action.boxId ?? action.driverId][logIndex].note = action.note
      } 
      return {
        ...changedState
      }
    default:
      return state;
  }
}

export const driving_log_summary = (state = {}, action) => {
  let updatedState
  switch (action.type) {
    case 'DRIVING_LOG_SUMMARIES_FETCHED':
      return action.data
    case 'FETCHING_DRIVING_LOG_SUMMARIES_FAILED':
      return {}
    case 'NOTE_ADDED_TO_LOG':
      updatedState = state
      if (updatedState?.length) {
        const index = updatedState.findIndex(summary => summary.driverId === action.driverId || summary.boxId === action.boxId)
        if (index > -1) {
          updatedState[index].routesWithoutNotes = updatedState[index].routesWithoutNotes - 1

          if (action.purpose === 'work') {
            updatedState[index].businessRoutesWithoutNotes = updatedState[index].businessRoutesWithoutNotes - 1
          }
        }
        return updatedState
      }
      else return state
    case 'NOTE_REMOVED_FROM_LOG':
      updatedState = state
      if (updatedState?.length) {
        const index = updatedState.findIndex(summary => summary.driverId === action.driverId || summary.boxId === action.boxId)
        if (index > -1) {
          updatedState[index].routesWithoutNotes = updatedState[index].routesWithoutNotes + 1

          if (action.purpose === 'work') {
            updatedState[index].businessRoutesWithoutNotes = updatedState[index].businessRoutesWithoutNotes + 1
          }
        }
        return updatedState
      }
      else return state
    default:
      return state;
  }
}

export const driving_log_details = (state = {}, action) => {
  switch (action.type) {
    case 'DRIVING_LOG_DETAILS_FETCHED':
      const fetchedBoxId = action.boxId
      const fetchedDriverId = action.driverId
      if (!fetchedDriverId && !fetchedBoxId) return state

      const key = (fetchedBoxId ?? fetchedDriverId) + '_' + action.start
      
      const updatedState = state
      updatedState[key] = action.data
      
      return {...updatedState}
    default:
      return state;
  }
}
export const current_drivers = (state = {data: {}, total: 0}, action) => {
  switch (action.type) {
    case 'CURRENT_DRIVERS_FETCHED':
      return {
        data: action.data,
        total: action.total,
        boxId: action.data.boxId
      }
    default:
      return state;
  }
}

export const current_mileage = (state = 0, action) => {
  switch (action.type) {
    case 'MILEAGE_FETCHED':
      return {mileage: action?.data, boxId: action.boxId}
    case 'NO_MILEAGE': return {mileage: 0, boxId: action.boxId}
    default:
      return state;
  }
}

// TODO: Handle server replies coming in the wrong order, like we do for vehicles.warning_logs
export const warning_logs = (state = {}, action) => {
  switch (action.type) {
    case "FETCH_WARNING_LOGS":
      return {
        ...state,
        fetchStartEpoch: action.start, // server responses can come in wrong order, want to know which is the right response
        fetchEndEpoch: action.end,
      }
    case 'WARNING_LOGS_FETCHED':
      if (action.start !== state.fetchStartEpoch || action.end !== state.fetchEndEpoch) {
        return state
      }

      return {
        ...state,
        data: action.data,
        total: action.total,
        start: action.start,
        end: action.end
      }
    default:
      return state;
  }
}

// stores the same info as warning_logs, but keeps track of previous queries. Allows us to display multiple logs at once, like on the driving log page
export const warning_logs_history = (state = {}, action) => {
  switch (action.type) {
    case 'WARNING_LOGS_FETCHED':
      const key = action.boxId ? action.boxId + '_' + action.start : 'all' + '_' + action.start + '_' + action.end

      const newState = {...state}
      newState[key] = {
        data: action.data,
        total: action.total,
        start: action.start,
        end: action.end
      }

      return newState
    default:
      return state;
  }
}

export const toll_reports = (state = {}, action) => {
  switch (action.type) {
    case 'TOLL_REPORTS_FETCHED':
    const fetchedBoxId = action.boxId
      const fetchedDriverId = action.driverId
      if (!fetchedDriverId && !fetchedBoxId) return state

      const key = (fetchedBoxId ?? fetchedDriverId) + '_' + action.start
      
      const updatedState = state
      updatedState[key] = {
        data: action.data,
        total: action.total,
        start: action.start,
        end: action.end
      }
      
      return {...updatedState}
    case 'TOLL_REPORT_SUMMARIES_FETCHED':
      return {
        ...state,
        summary: {
          data: action.data,
          start: action.start,
          end: action.end
        }
      }
    default:
      return state
  }
}

export const vehicles_data = (state = {data: [], total: 0}, action) => {
  switch (action.type) {
    case 'VEHICLES_DATA_FETCHED':
      return {
        data: action.data.boxes,
        total: action.data.total
      }
    default:
      return state;
  }
}


export const signalRConnection = (state = null, action) => {
  switch (action.type) {
    case 'SIGNALR_CONNECTION_STARTED':
      return action.data
    case 'SIGNALR_CONNECTION_STOPPED':
      return null
    default:
      return state
  }
}


export const notificationsSignalRConnection = (state = null, action) => {
  switch (action.type) {
    case 'NOTIFICATIONS_SIGNALR_CONNECTION_STARTED':
      return action.data
    case 'NOTIFICATIONS_SIGNALR_CONNECTION_STOPPED':
      return null
    default:
      return state
  }
}