import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import TableWidgetSeparated from '@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2';
import { log_columns } from './logColumns'
import {log_columns_collapsable} from './logColumnsCollapsable'
import View from "@modul-connect/shared/components/atoms/view/view";
import {CustomTooltip} from "@modul-connect/shared/components/molecules/tooltip"
import { useAuth0 } from "@auth0/auth0-react";
import { fetchMaintenancePlans, fetchMaintenanceTasks } from "../../../../../state/actions/vehicles";
import { prepareData } from "./viewAllTable";
import Vehicle from '@modul-connect/shared/img/vehicle.svg'
import Workshop from '@modul-connect/shared/img/workshop.svg'
import OnSite from '@modul-connect/shared/img/on_site.svg'

export const getIconForHomebaseType = (homebaseType) => {
  const VehicleIcon = <img src={Vehicle} />
  const WorkshopIcon = <img src={Workshop} />
  const OnSiteIcon = <img src={OnSite} />
  
  return homebaseType === 'Vehicle' 
    ? VehicleIcon 
    : homebaseType === 'Workshop'
      ? WorkshopIcon 
      : OnSiteIcon 
}


const hideHomebaseColumnFromCollapsible = true // not needed on this page
const HomebaseViewTable = ({
  themes,
  searchFilter,
  maintenance_summary,
  fetchMaintenanceTasks,
  loading,
  fetchMaintenancePlans,
  showVehicleHomebases,
  showWorkshopHomebases,
  showOnSiteHomebases,
  openAssetMaintenanceTaskModal
}) => {
  let [data, setData] = useState([])  
  let [allData, setAllData] = useState([])

  const { getAccessTokenSilently } = useAuth0();

  const prepareSummaryData = () => {
    const data = []

    if(maintenance_summary?.length)
    {
      
      maintenance_summary.filter(s => s.equipments > 0).map(s =>
        data.push(
          {
            key: s?.homeBaseId,
            id: s?.homeBaseId,
            homebase: s?.homeBaseName,
            homeBase_type: s?.homeBaseType,
            homebase_icon: getIconForHomebaseType(s?.homeBaseType),
            equipments: s?.equipments,
            maintenance: <MaintenanceDisplay data={{
              overdue: s?.overdueTasks,
              due: s?.withinOneWeekTasks,
              later: s?.withinThreeWeekTasks,
            }}/>,
            maintenance_sort: s?.overdueTasks * 1000 + s?.withinOneWeekTasks + s?.withinThreeWeekTasks * 0.001,
            collapsibleData: s?.tasks ? prepareData(s?.tasks, hideHomebaseColumnFromCollapsible, openAssetMaintenanceTaskModal) : [],
            isExpandable: true,
            onExpand:() => {
              getAccessTokenSilently().then((accessToken) => {
                fetchMaintenanceTasks(accessToken, s?.homeBaseId);
              })
            },
          }
        )) 
        setAllData(data)
    }
  }

  useEffect(() => {
    let filteredData = []
    let unfilteredData = allData

    if(showVehicleHomebases)
      filteredData = filteredData.concat(unfilteredData?.filter(d => d?.homeBase_type === 'Vehicle'))

    if(showWorkshopHomebases)
      filteredData = filteredData.concat(unfilteredData?.filter(d => d?.homeBase_type === 'Workshop'))

    if(showOnSiteHomebases)
      filteredData = filteredData.concat(unfilteredData?.filter(d => d?.homeBase_type === 'OnSite'))

    if( filteredData?.length && searchFilter){
      filteredData = filteredData?.filter(d => d?.homebase?.toLowerCase()?.includes(searchFilter.toLowerCase()))
      setData(filteredData)
      return
    }

    if (!searchFilter) {
      setData(filteredData)
      return
    }
    setData(unfilteredData)

  }, [searchFilter, allData, showVehicleHomebases, showWorkshopHomebases, showOnSiteHomebases])

  useEffect(() => {
    prepareSummaryData()
  }, [maintenance_summary])

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) =>
      fetchMaintenancePlans(accessToken))
  }, [])

  return( 
      <TableWidgetSeparated
        data={data}
        columns={log_columns}
        defaultSort={'homebase'}
        loadingStatus={loading?.fetchMaintenanceSummary}
        themes={themes}
        isSortable
        pagination={false}
        rowsOptions={[data?.length]}
        collapsibleData={data?.collapsibleData}
        collapsibleLogColumns={log_columns_collapsable}
        collapsibleDataLoadingStatus={loading?.fetchMaintenanceTasks}
        collapsibleDefaultSortIndex={4}
        onelineOnly
      />
    )
}

const MaintenanceDisplay = ({data}) => {
  return (
    <View style={{ display: "flex", gap: 7 }}>
      { data.overdue ?
      <CustomTooltip title={'Overdue'} placement={'bottom'}>
        <MaintenanceValue value={data.overdue} color={'#FFC4C4'}/>  
      </CustomTooltip>
        
      : null
    }
    {
      data.due ? 
        <CustomTooltip title={'Less than a week to go'} placement={'bottom'}>
          <MaintenanceValue value={data.due} color={'#FFF0B9'}/>
          </CustomTooltip>
    : null
    }
    {
      data.later ?
      <CustomTooltip title={'1-3 week to go'} placement={'bottom'}>
        <MaintenanceValue value={data.later} color={'#DCE6FF'}/>
        </CustomTooltip>
      : null
    }
    {
      !data.overdue && !data.later && !data.due ?
      <MaintenanceValue value={0} color={'transparent'}/>
      : null
    }
    </View>
  );
}

const MaintenanceValue = ({value, color}) => {
  return(
      <View style={{ backgroundColor: color, fontWeight: 300, padding: 3, width: 'max-content' }}>{value}</View>
  )
}



const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
  fetchMaintenanceTasks: (acceessToken, homebase) => dispatch(fetchMaintenanceTasks(acceessToken, homebase)),
  fetchMaintenancePlans: (accessToken) => dispatch(fetchMaintenancePlans(accessToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomebaseViewTable);