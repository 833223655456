import { DateTime } from 'luxon'

const getNowHour = () => Math.ceil(DateTime.local().endOf('hour').toSeconds())
const getNowDay = () => Math.ceil(DateTime.local().endOf('day').toSeconds())

export const ticks_24_hours = () => {
  const nowHour = getNowHour()
  return [
    nowHour - (60 * 60 * 24), 
    nowHour - (60 * 60 * 22), nowHour - (60 * 60 * 20),
    nowHour - (60 * 60 * 18), nowHour - (60 * 60 * 16),
    nowHour - (60 * 60 * 14), nowHour - (60 * 60 * 12),
    nowHour - (60 * 60 * 10), nowHour - (60 * 60 *  8),
    nowHour - (60 * 60 *  6), nowHour - (60 * 60 *  4),
    nowHour - (60 * 60 *  2), nowHour
  ]
}

export const ticks_7_days = () => {
  const nowDay = getNowDay()
  return [
    nowDay - (60 * 60 * 24 * 7), 
    nowDay - (60 * 60 * 24 * 6), 
    nowDay - (60 * 60 * 24 * 5), 
    nowDay - (60 * 60 * 24 * 4),
    nowDay - (60 * 60 * 24 * 3),
    nowDay - (60 * 60 * 24 * 2),
    nowDay - (60 * 60 * 24 * 1),
    nowDay
  ]
}