export const updateAsset = (
  accessToken, 
  organisationId,
  assetName,
  category,
  homebaseId,
  brand,
  serialNumber,
  assetId,
  oldAssetAndTask,
  assetImages,
  serialIdImages
  ) => (
  { 
    type: 'UPDATE_ASSET',
    accessToken, 
    organisationId,
    assetName,
    category,
    homebaseId,
    brand,
    serialNumber,
    assetId,
    oldAssetAndTask,
    assetImages,
    serialIdImages
  }
)

export const updateMaintenancePlan = (
  accessToken,
  assetId,
  oldMaintenancePlan,
  newFrequencyInDays,
  newDueDate,
  maintenancePlanId,
  maintenancePeriodId
) => (
  {
    type: 'UPDATE_MAINTENANCE_PLAN_AND_TASK',
    accessToken,
    assetId,
    oldMaintenancePlan,
    newFrequencyInDays,
    newDueDate,
    maintenancePlanId,
    maintenancePeriodId
  }
)

export const markMaintenancePeriodAsDone = (
  accessToken,
  periodId,
  endDate,
  document,
  comment,
  maintenancePlanId,
  assetId,
  fileName
) => ({
  type: 'MARK_MAINTENANCE_PERIOD_AS_DONE',
  accessToken,
  periodId,
  endDate,
  document,
  comment,
  maintenancePlanId,
  assetId,
  fileName
})

export const updateAssetAndMaintenancePlan = (
  accessToken, 
  organisationId,
  assetName,
  category,
  homebaseId,
  brand,
  serialNumber,
  assetImages,
  serialImages,
  assetId,
  oldAssetAndTask,

  newFrequencyInDays,
  newDueDate,
  maintenancePlanId,
  maintenancePeriodId
  ) => (
  { 
    type: 'UPDATE_ASSET_AND_MAINTENANCE_PLAN',
    accessToken, 
    organisationId,
    assetName,
    category,
    homebaseId,
    brand,
    serialNumber,
    assetImages,
    serialImages,
    assetId,
    oldAssetAndTask,

    newFrequencyInDays,
    newDueDate,
    maintenancePlanId,
    maintenancePeriodId
  }
)

export const fetchMaintenancePeriodsWithDocuments = ( accessToken, assetId) => 
({
  type: 'FETCH_PERIODS_WITH_DOCUMENTS',
  accessToken,
  assetId
})

export const updateMaintenancePeriodComment = (accessToken, assetId, periodId, planId, newComment, oldComment) => ({
  type: 'UPDATE_MAINTENANCE_PERIOD_COMMENT',
  accessToken,
  assetId,
  periodId,
  planId,
  newComment,
  oldComment
})

export const updateDocumentOfPeriod = (accessToken, documentUrl, fileName, periodId, planId) => (
  { 
    type: 'UPDATE_DOCUMENT_OF_PERIOD',
    accessToken,
    documentUrl,
    fileName,
    periodId,
    planId
  }
)

export const removeDocumentFromPeriod = (accessToken, periodId, assetId) => (
  { 
    type: 'REMOVE_DOCUMENT_FROM_PERIOD',
    accessToken,
    periodId,
    assetId
  }
)

export const removeAssetDocument = (accessToken, documentId, assetId) => (
  { 
    type: 'REMOVE_ASSET_DOCUMENT',
    accessToken,
    documentId,
    assetId,
  }
)

export const updateAssetDocument = (accessToken, documentUrl, fileName, assetId, existingDocumentId) => ({
  type: 'UPDATE_ASSET_DOCUMENT',
  accessToken,
  documentUrl,
  fileName,
  assetId,
  existingDocumentId
})

export const addDocumentToAsset = (accessToken, documentUrl, fileName, assetId, comment) => ({
  type: 'ADD_ASSET_DOCUMENT',
  accessToken,
  documentUrl,
  fileName,
  assetId,
  comment
})

export const fetchAssetDocuments = (accessToken, assetId) => ({
  type: 'FETCH_ASSET_DOCUMENTS',
  accessToken,
  assetId
})

export const fetchAssetCategories = (accessToken) => (
  { 
    type: 'FETCH_ASSET_CATEGORIES',
    accessToken
  }
)