import React from "react";
import Widget from "@modul-connect/shared/components/atoms/widget";
import theme from "@modul-connect/shared/theme"
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { DateTime } from "luxon";
import { Fragment } from "react";

const LogChart = ({ 
  data, 
  data_key, 
  data_name, 
  max_name,
  min_name,
  warning_max_name,
  warning_min_name,
  title, 
  unit, 
  domain,
  displayTimeInHours,
  intervalOptions,
  onIntervalChanged,
  selectedInterval,
  ticks,
  line_type,
  yAxisPadding }) => {

    let tickFormatter =
        displayTimeInHours ?
            (epochs) => {
                let date = DateTime.fromSeconds(epochs)
                return (
                    (date.hour > 9 ? (date.hour) : ('0' + date.hour))
                    + ':' +
                    (date.minute > 9 ? (date.minute) : ('0' + date.minute))
                )
            } :
            (epochs) => {
                let date = DateTime.fromSeconds(epochs)
                return (
                    (date.year) + '-' +
                    (date.month > 9 ? (date.month) : ('0' + date.month)) + '-' +
                    (date.day > 9 ? (date.day) : ('0' + date.day))
                )
            }
    return (
      <Fragment>
        <Widget
            show={ data ? true : false }
            title={title}
            intervalOptions={intervalOptions }
            onOptionSelected={ onIntervalChanged }
            selectedInterval={ selectedInterval }
            children={[
                {
                    name: 'right',
                    body:
                    <ResponsiveContainer width="99%">
                        <LineChart data={ data ? data : []} margin={{ top: 20 }}>
                            <CartesianGrid stroke={ "#ccc" } strokeDasharray="3" />
                            <XAxis
                                dataKey="timestamp"
                                interval="preserveStartEnd"
                                tick={{fontSize: 14}}
                                type="number"
                                ticks={ ticks }
                                domain={[   DateTime.local().toSeconds() - (60 * 60 * 24),
                                            DateTime.local().toSeconds()]}
                                tickFormatter={ tickFormatter }
                            />
                            <YAxis
                                tick={{fontSize: 14}}
                                allowDataOverflow={true}
                                domain={ domain }
                                type="number"
                                unit={ unit }
                                padding={yAxisPadding}
                            />
                            <Tooltip
                               labelFormatter={ (epochs) =>
                                    {
                                        let date = DateTime.fromSeconds(epochs)
                                        return (
                                            (date.year) + '-' +
                                            (date.month > 9 ? (date.month) : ('0' + date.month)) + '-' +
                                            (date.day > 9 ? (date.day) : ('0' + date.day)) + ' ' +
                                            (date.hour > 9 ? (date.hour) : ('0' + date.hour)) + ':' +
                                            (date.minute > 9 ? (date.minute) : ('0' + date.minute))
                                    )}}
                            />
                            
                            {DataLine({ line_type: line_type, data_name: data_name, data_key: data_key })}
                          
                            {IssueLine({ dataKey: "max", name: (max_name ?? 'max') })}
                            {WarningLine({ dataKey: "warning_max", name: (warning_max_name ?? 'warning_max') })}
                            {WarningLine({ dataKey: "warning_min", name: (warning_min_name ?? 'warning_min') })}
                            {IssueLine({ dataKey: "min", name: (min_name ?? 'min') })}
                        </LineChart>
                    </ResponsiveContainer>
                }
            ]}
        />
      </Fragment>
    )
}
 
// These line components need to be called as functions for some reason, for the LineChart to recognise them.

const DataLine = ({ line_type, data_name, data_key }) => {
  return (
    <Line 
      dot={false}
      activeDot
      type={ line_type || "linear" }
      name={ data_name }
      dataKey={ data_key }
      stroke={ theme.colors.black }
      strokeWidth={2}
    />
  )
}

const WarningLine = ({ dataKey, name }) => {
  return (
    <Line 
      dot={false}
      dataKey={dataKey}
      name={ name }
      stroke={theme.colors.tag['warning']}
      activeDot={false}
      legendType="none"
      strokeDasharray="3 2"
      strokeWidth={1.75}
    />
  )
}

const IssueLine = ({ dataKey, name }) => {
  return (
    <Line 
      dot={false}
      dataKey={dataKey}
      name={ name }
      stroke={theme.colors.tag['issue']}
      activeDot={false}
      legendType="none"
      strokeDasharray="14 10"
      strokeWidth={1.75}
    />
  )
}

export default LogChart
