import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import SimpleTable from "@modul-connect/shared/components/molecules/tableWidget/simpleTable"
import { dateTimeToString } from "@modul-connect/shared/utils/dateTimeUtils";
import { log_columns_viewAll } from "./logColumnsCollapsable";
import { DateTime } from "luxon";
import BluetoothIcon from '@modul-connect/shared/img/BluetoothIcon.svg'
import View from '@modul-connect/shared/components/atoms/view'
import { SimpleTableValue } from "@modul-connect/shared/components/atoms/text/text";
import { getIconForHomebaseType } from "./homebaseViewTable";


const dueDateToStr = (dueDate) => {
  return dateTimeToString(DateTime.fromISO(dueDate), 'day-month-and-year') + ' '  + getDaysUntilTaskDue(dueDate)
}

const dueDateToView = (dueDate, task) => {
  return (
    <View style={{
        display: 'flex', 
        flexDirection: 'row', 
        backgroundColor: task.isOverdue  ? '#FFC4C4' : task.isInOneWeek ? '#FFF0B9' : task.isInThreeWeeks ? '#DCE6FF' : 'transparent', 
        gap: 5, 
        width: 'fit-content',
        padding: '2px 6px',  
      }}>
      <SimpleTableValue
        noTooltip
        maxOneLine
        text={dateTimeToString(DateTime.fromISO(dueDate), 'day-month-and-year')  + ' '}
      />
      <SimpleTableValue
        noTooltip
        maxOneLine
        text={getDaysUntilTaskDue(dueDate)}
        extend={{fontWeight: 700}}
      />
      
    </View>)
}

const getFrequencyStr = (daysFrequency) => { // TODO: use same names as for plan names
  const daysFrequencyInInt = Number.parseInt(daysFrequency)
  return daysFrequencyInInt ? ('Every ' + daysFrequencyInInt + ' days') : 'One-time event'
}

export const getDaysUntilTaskDue = (date) => {
  if(!date) return ""

  const currentDateTime = DateTime.local().startOf('day')
  const dueDate = DateTime.fromISO(date).startOf('day')
  
  const daysDifference = dueDate.diff(currentDateTime, 'days').toObject().days
  if(daysDifference < 0)
    return "Overdue"
  if (daysDifference === 0) return 'Today'
  else if (daysDifference <= 7)
    return daysDifference + ' days'
  else 
    return ""
}

const getImageTooltip = (images, assetName) =>
{
  return(
    <View style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
      {
        images?.length 
        ? <View style={{display: 'flex', width:'fit-content', flexDirection: 'row', gap: images?.length > 1 ? 5 : 0}}>
          {
            images.map(img =>
              img.typeOfImage === 'Asset' ? <img src={img.imageUrl} style={{maxWidth: 120, maxHeight: 120}}/> : null
            )
          }
          </View> 
        : null
      }
      <View>
        {assetName}
      </View>
    </View>
  )
}

export const prepareData = (unformattedData, hideHomebaseColumn, openAssetMaintenanceTaskModal) =>{
  const formatedData = []

  unformattedData.map(task => {
    const cells = []
    cells.push(
      {iconComponent: <img src={task.category.iconUrl} alt={task.category.name} />, tooltip: task.category.name, sortValue: task.category.name },
      {value: task?.assetName, tooltip: getImageTooltip(task?.images, task?.assetName)},
      {icon:  task?.beaconId ? BluetoothIcon : null, tooltip: task?.beaconId ? 'Asset is being tracked' : 'No asset tracking', sortValue: task?.beaconId ? 1 : 0 },
      {value: task?.brand},
      {
        component: dueDateToView(task?.dueDate, task),
        tooltip: task?.dueDate ? 'Due date: ' + dateTimeToString(DateTime.fromISO(task?.dueDate), 'day-month-and-year') : 'No due date',
        sortValue: DateTime.fromISO(task?.dueDate),  
      },
    )
    if (!hideHomebaseColumn) {
      cells.push({
        iconComponent: getIconForHomebaseType(task?.homebaseType),
        value: task?.homebase,
        tooltip: task?.homebase,
        sortValue: task?.homebase,
      })
    }
    cells.push({value: getFrequencyStr(task?.daysFrequency), sortValue: task?.daysFrequency ? parseInt(task?.daysFrequency) : Infinity })

    cells.push({value: 'Details', onClick: () => openAssetMaintenanceTaskModal(task.maintenancePlanId) })

    formatedData.push(cells)
  })

  return formatedData?.sort((a, b) => (a[4]?.dueDate > b[4]?.dueDate) ? 1 : -1)
}

const ViewAllTaskTable = ({
  maintenance_tasks,
  loading,
  searchFilter,

  showVehicleHomebases,
  showWorkshopHomebases,
  showOnSiteHomebases,

  openAssetMaintenanceTaskModal
}) =>
{
  const [data, setData] = useState([])
  let [allData, setAllData] = useState([])

  useEffect(() => {
    let filteredData = []
    let unfilteredData = allData

    if(showVehicleHomebases)
      filteredData = filteredData.concat(unfilteredData?.filter(d => d?.homebaseType === 'Vehicle'))

    if(showWorkshopHomebases)
      filteredData = filteredData.concat(unfilteredData?.filter(d => d?.homebaseType === 'Workshop'))

    if(showOnSiteHomebases)
      filteredData = filteredData.concat(unfilteredData?.filter(d => d?.homebaseType === 'OnSite'))

    if(filteredData?.length && searchFilter){
      filteredData = filteredData?.filter(d => (
        d?.assetName?.toLowerCase()?.includes(searchFilter.toLowerCase()) ||
        d?.brand?.toLowerCase()?.includes(searchFilter.toLowerCase()) ||
        d?.homebase.toLowerCase()?.includes(searchFilter.toLowerCase()) ||
        dueDateToStr(d?.dueDate)?.toLowerCase()?.includes(searchFilter.toLowerCase()) ||
        getFrequencyStr(d?.daysFrequency)?.includes(searchFilter.toLowerCase())
      ))
      setData(filteredData)
      return
    }

    if (!searchFilter) {
      setData(filteredData)
      return
    }
    setData(unfilteredData)
  }, [searchFilter, allData, showVehicleHomebases, showWorkshopHomebases, showOnSiteHomebases])
  
  useEffect(() =>{
    if(maintenance_tasks?.tasks?.length) 
      setAllData(maintenance_tasks?.tasks)
  },[maintenance_tasks?.tasks])

  return(
    <SimpleTable 
      columns={log_columns_viewAll}
      data={prepareData(data, false, openAssetMaintenanceTaskModal)}
      isLoading={loading?.fetchMaintenanceTasks === 'loading'}
      defaultSortIndex={4}
    />
  )
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllTaskTable);