import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { InputField } from '@modul-connect/shared/components/atoms/textInput/inputField'
import View from '@modul-connect/shared/components/atoms/view'
import { DateSelector } from "@modul-connect/shared/components/molecules/daySelector/daySelector";
import { DateTime } from "luxon";
import Button from "@modul-connect/shared/components/atoms/button/button.v2";
import NoBluetooth from '@modul-connect/shared/img/BluetoothIconCrossedOut.svg'
import BlueImmage from '@modul-connect/shared/img/blue_image_icon.svg'
import Vehicle from '@modul-connect/shared/img/vehicle.svg'
import Workshop from '@modul-connect/shared/img/workshop.svg'
import OnSite from '@modul-connect/shared/img/on_site.svg'
import { createAssetForMaintenance, removeImage } from "../../../../state/actions/vehicles";
import { useAuth0 } from "@auth0/auth0-react";
import { InfoText, Link } from '@modul-connect/shared/components/atoms/text/text'
import { Dropdown } from '@modul-connect/shared/components/molecules/dropdown/dropdown'
import { MdDelete } from "react-icons/md";
import { CircularProgress } from "@mui/material";
import { getCategoriesForDropdown, getHomebaseOptionsForDropdown } from "../../../../utils/assets";
import { checkThatIsNumber } from "@modul-connect/shared/utils/text.js"
import { ModalBase, WhitePaperBackground } from "@modul-connect/shared/components/molecules/modal/ModalBase";

export const AddHomebase = 'Add new homebase'

const BlueImmageIcon = <img src={BlueImmage} />
const NoBluetoothIcon = <img src={NoBluetooth} />
export const VehicleIcon = <img src={Vehicle} />
export const WorkshopIcon = <img src={Workshop} />
export const OnSiteIcon = <img src={OnSite} />


const defaultPlans = [{
  name: 'One-time event',
  value: 0
},{
  name: 'Weekly',
  value: 7
},{
  name: 'Every 2 weeks',
  value: 14
},
{
  name: 'Every 1 month',
  value: 30
},
{
  name: 'Every 2 months',
  value: 60
},
{
  name: 'Every 3 months',
  value: 90
},
{
  name: 'Every 6 months',
  value: 180
},
{
  name: 'Every 1 year',
  value: 360
},
{
  name: 'Every 2 years',
  value: 720
}]

export const getFrequencyOptions = (plans) => {
  if (!plans?.length) return defaultPlans.map(plan => { return {id: plan.name, value: plan.value, name: plan.name}});
  
  const options = defaultPlans.map((defaultPlan) => {
    return { id: defaultPlan.name, value: defaultPlan.value, name: defaultPlan.name }
  })

  const addPlans = plans?.forEach((plan) => { // addPlans doesn't do anything, but prevents (supposed) syntax error
    if (!options.some(o => (o.value == plan.daysFrequency || (!o.value && !plan.daysFrequency)))) options.push({
      id: plan.name,
      value: plan.daysFrequency,
      name: plan.daysFrequency <= 30
        ? (!plan.daysFrequency || plan.daysFrequency == 0) ? 'One-time event' : 'Every ' + plan.daysFrequency + ' days'
        : 'Every ' + Math.floor(plan.daysFrequency / 30) + ' months'
    })
  })

  return options?.sort((a, b) => a.value > b.value ? 1 : -1)
}

export const getHomebaseTypeIcon = (type) => {
  return type?.contains === 'Workshop'
    ? WorkshopIcon
      : type?.contains === 'Vehicle'
        ? VehicleIcon
        : OnSiteIcon
}

const getHomebaseByName = (homebases, homebaseName) => {
  return homebases?.find(h => h.name === homebaseName || h.physicalVehiclesName === homebaseName)
}

// TODO: get styling closer to figma, especially paper, title, box shadow
const AddMaintenanceModal = ({
  open,
  onClose,
  onSave,
  maintenance_plans,
  initCreateNewHomebase,
  homebaseInput,
  createAssetForMaintenance,
  openUploadImage,
  imageUploaded,
  removeImage,
  setModalForUploadingImage,
  saving
}) => {
  const [name, setName] = useState('')
  const [brand, setBrand] = useState('')
  const [serialNumber, setSerialNumber] = useState('')
  const [category, setCategory] = useState('')
  const [homebaseName, setHomebaseName] = useState('')
  const [organisation, setOrganisation] = useState('')
  const [frequency, setFrequency] = useState('')
  const [date, setDate] = useState(DateTime.local())
  //const [openedNewHomebase, setOpenedNewHomebase] = useState(false)
  const [openedAddImage, setOpenedAddImage] = useState(false)
  const [openedAddImageOfSerial, setOpenedAddImageOfSerial] = useState(false)
  const [uplodedAssetsImage, setUplodedAseetsImage] = useState([])
  const [uplodedImageOfSerial, setUplodedImageOfSerial] = useState([])
  const [hasBeenUploadedAssetImage, setHasBeenUploadedAssetImage] = useState(null)
  const [hasBeenUploadedSerialImage, setHasBeenUploadedSerialImage] = useState(null)
  const [organisationsToSelectFrom, setOrganisationsToSelectFrom] = useState([]) // not currently in use

  const [backupHomebaseName, setBackupHomebaseName] = useState('')
  const [backupOrganisation, setBackupOrganisation] = useState('')

  const backupSelectedHomebase = () => {
    setBackupHomebaseName(homebaseName)
    setBackupOrganisation(organisation)
  }

  useEffect(() => {
    if (!homebaseInput) return
    
    if (!homebaseInput.id) {
      setOrganisation(backupOrganisation)
      setHomebaseName(backupHomebaseName)

      setBackupHomebaseName('')
      setBackupOrganisation('')
    }
    
    else {
      setOrganisation(homebaseInput?.organisationId)
      setHomebaseName(homebaseInput?.homebaseName)
    }
  }, [homebaseInput])

  useEffect(() => {
    if(frequency || frequency === 0)
      setDate(DateTime.local().plus({ days: frequency }))
  }, [frequency])

  useEffect(() => {
    if (homebaseName === AddHomebase) {
      initCreateNewHomebase()
    }
  }, [homebaseName])

  useEffect(() => {
    if (!openedAddImage && !openedAddImageOfSerial) {
      setName('')
      setBrand('')
      setSerialNumber('')
      setCategory('')
      setHomebaseName('')
      setFrequency('')
      setUplodedAseetsImage([])
      setHasBeenUploadedAssetImage(null)
      setUplodedImageOfSerial([])
      setHasBeenUploadedSerialImage(null)
    }
  }, [open])

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (openedAddImage) {
      setUplodedAseetsImage(imageUploaded)
      setOpenedAddImage(false)
    }
    if (openedAddImageOfSerial) {
      setUplodedImageOfSerial(imageUploaded)
      setOpenedAddImageOfSerial(false)
    }
  }, [imageUploaded])

  const sendRequestForNewAsset = () => {
    const homeBaseSelected = getHomebaseByName(maintenance_plans?.homebases, homebaseName)
    const assetImages = []
    const serialImages = []
    if (maintenance_plans?.images?.length)
      maintenance_plans.images.map(img => {
        if (img.typeOfImage === 'Asset')
          assetImages.push(img)
        else if (img.typeOfImage === 'Serial_id')
          serialImages.push(img)
      })

    getAccessTokenSilently().then((accessToken) =>
      createAssetForMaintenance(
        accessToken,
        (organisation && organisation !== '') ? organisation : homeBaseSelected?.organisationId,
        name?.trim(),
        category?.trim() ?? null,
        homeBaseSelected?.id,
        homeBaseSelected?.type,
        brand?.trim(),
        serialNumber.trim(),
        frequency ? frequency : null,
        date.toISO({ includeOffset: false }),
        null,
        null,
        assetImages,
        serialImages
      )
    )
  }



  const hasUploadedImages = (
    !saving.uploadImage || (saving.uploadImage === 'uploaded')
  )

  const disabled = (
    name.trim() === '' ||
    brand.trim() === '' ||
    category.trim() === '' ||
    homebaseName.trim() === '' || homebaseName === AddHomebase ||
    !date ||
    !(frequency || frequency === 0) ||
    !hasUploadedImages
  )

  const sendRemoveImage = (image) => {
    if (!image) return
    const selectedImage = maintenance_plans.images.find(img => img.fileData.fileName === image.name)
    getAccessTokenSilently()
      .then((accessToken) => removeImage(accessToken, selectedImage?.fileData?.imageId))
  }

  useEffect(() => {
    if (!maintenance_plans.images?.length) {
      setUplodedAseetsImage([])
      setUplodedImageOfSerial([])
      return
    }
    maintenance_plans.images.map(img => {
      if(img.typeOfImage === 'Asset')
      {
        if (uplodedAssetsImage?.name && img?.fileData?.fileName === uplodedAssetsImage?.name )
          setHasBeenUploadedAssetImage(true)
        else
          setHasBeenUploadedAssetImage(null)
      }
      else if(img.typeOfImage === 'Serial_id')
      {
        if (uplodedImageOfSerial?.name && img?.fileData?.fileName === uplodedImageOfSerial?.name)
          setHasBeenUploadedSerialImage(true)
        else
          setHasBeenUploadedSerialImage(null)
      }
    })
  }, [maintenance_plans?.images])

  // TODO: if can't save due to wrong inputs, tell user why
  return (
    <ModalBase onClose={onClose} open={open} title={'Add new equipment for maintenance'}>
      <View style={{ display: 'flex', flexDirection: 'row', gap: 8, marginBottom: 16 }}>
        <View style={{ alignSelf: 'flex-start' }}>
          {NoBluetoothIcon}
        </View>
        
        <InfoText>
          Important! This option is intended for non-trackable equipment only. To add trackable equipment, please use the Modul-Connect mobile app.
        </InfoText>
      </View>

      <InputSection>
        <UploadImagesView
          sendRemoveImage={sendRemoveImage}
          uplodedAssetsImage={uplodedAssetsImage}
          openUploadImage={openUploadImage}
          setOpenedAddImage={setOpenedAddImage}
          uplodedImageOfSerial={uplodedImageOfSerial}
          hasBeenUploadedSerialImage={hasBeenUploadedSerialImage}
          setUplodedImageOfSerial={setUplodedImageOfSerial}
          setUplodedAseetsImage={setUplodedAseetsImage}
          setOpenedAddImageOfSerial={setOpenedAddImageOfSerial}
          hasBeenUploadedAssetImage={hasBeenUploadedAssetImage}
          setModalForUploadingImage={setModalForUploadingImage}
        />

        <InputField placeholder={'Name'} label={'Name'} value={name} onChange={setName} isRequired />

        <InputField placeholder={'Brand'} label={'Brand'} value={brand} onChange={setBrand} isRequired />

          <InputField placeholder={'Serial Number'} label={'Serial Number'} value={serialNumber} onChange={setSerialNumber}/>

          <Dropdown options={getCategoriesForDropdown(maintenance_plans)} placeholder={'Select category'} value={category} onChange={setCategory} isRequired />

          <Dropdown
            options={getHomebaseOptionsForDropdown(maintenance_plans, true, backupSelectedHomebase)}
            disabled={saving?.newHomebase === "saving"}
            processing={saving?.newHomebase === "saving"}
            placeholder={'Select homebase'}
            value={homebaseName}
            onChange={setHomebaseName}
            isRequired />

          {organisationsToSelectFrom?.length > 1 ?
            <Dropdown
              options={organisationsToSelectFrom?.map(org => { return { name: org.organisationName, id: org.organisationId } })}
              placeholder={'Select organisation'}
              value={organisation}
              onChange={setOrganisation}
              isRequired />
            : null
          }

          <Dropdown
            title={'Maintenance frequency'}
            options={getFrequencyOptions([])}
            placeholder={'Select frequency'}
            value={frequency}
            onChange={setFrequency}
            isRequired />


            <DateSelector
              title={"Due date"}
              date={date}
              onDayPicked={(newDate) => setDate(newDate)}
              disableDaysBefore={DateTime.local()}
              isRequired
            />

          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', fontSize: 12 }}>
            * Required fields
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }}>
            <Button
              extend={{ width: '30%' }}
              disabled={disabled}
              onClick={() => {
                sendRequestForNewAsset();
                onSave()
              }}
            >
              {'Save'}
            </Button>

            <Button
              noBg
              border
              onClick={() => {
                onClose()
              }}
            >
              {'Cancel'}
            </Button>
          </View>

          </InputSection>
    </ModalBase>
  )
}

const ShowUploadedImageInfo = ({
  uplodedImage,
  onClick,
  enableDelete
}) => {
  return (
    <View >
      {
        uplodedImage?.name
          ?
          <View style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <View style={{ display: 'inline-block', overflow: 'hidden' }}>
              {
                uplodedImage.name
              }
            </View>
            <View style={{width:'30px'}}>
              { 
                enableDelete 
                ? <MdDelete onClick={onClick} style={{ cursor: 'pointer'}} /> 
                : <CircularProgress size={20} color="inherit" />
              }
            </View> 
          </View>
          : null
      }
    </View>
  )
}

const InputSection = ({ children }) => {
  return (
    <WhitePaperBackground fullView>
      {children}
    </WhitePaperBackground>
  )
}

const UploadImagesView = ({
  sendRemoveImage,
  uplodedAssetsImage,
  openUploadImage,
  setOpenedAddImage,
  uplodedImageOfSerial,
  hasBeenUploadedSerialImage,
  setUplodedImageOfSerial,
  setUplodedAseetsImage,
  setOpenedAddImageOfSerial,
  hasBeenUploadedAssetImage,
  setModalForUploadingImage
}) => {
  return (
    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: 15 }}>

      <View style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Link
          underline
          icon={BlueImmageIcon}
          onClick={() => {
            openUploadImage(true)
            setOpenedAddImageOfSerial(false)
            setOpenedAddImage(true)
            setModalForUploadingImage('Asset')
          }}
        >{'Add image'}</Link>

        <ShowUploadedImageInfo
          uplodedImage={uplodedAssetsImage}
          enableDelete={hasBeenUploadedAssetImage}
          onClick={() => {
            setUplodedAseetsImage(null);
            sendRemoveImage(uplodedAssetsImage)
          }
          }
        />

      </View>

      <View style={{ flex:1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Link
          underline
          icon={BlueImmageIcon}
          onClick={() => {
            openUploadImage(true)
            setOpenedAddImage(false)
            setOpenedAddImageOfSerial(true)
            setModalForUploadingImage('Serial_id')
          }}
        >{'Add image of serial number'}</Link>

        <ShowUploadedImageInfo
          uplodedImage={uplodedImageOfSerial}
          enableDelete={hasBeenUploadedSerialImage}
          onClick={() => {
            setUplodedImageOfSerial(null)
            sendRemoveImage(uplodedImageOfSerial)
          }}
        />
      </View>
    </View>
  )
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
  createAssetForMaintenance: (accessToken, organisationId, assetName, category, homebase, homebaseType, brand, serialNumber, frequency, dueDate, instructions, maintenanceName, assetImages, serialImages) =>
    dispatch(createAssetForMaintenance(accessToken, organisationId, assetName, category, homebase, homebaseType, brand, serialNumber, frequency, dueDate, instructions, maintenanceName, assetImages, serialImages)),
  removeImage: (accessToken, imageId) => dispatch(removeImage(accessToken, imageId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMaintenanceModal);