import { DateTime } from "luxon"
import theme from '@modul-connect/shared/theme';
import simplify from '@turf/simplify'
import * as turf from '@turf/helpers'
import { getLastPosition } from "../pages/dashboard/map/utils";
import { isHumiditySensorBroken } from "@modul-connect/shared/config";


export const weightWarningThreshold = 100
export const weightIssueThreshold = 107

export const getVehicleName = (vehicles, boxId) => {
  
    if (vehicles && vehicles.length > 0) {
    let found = vehicles.find(v => v.boxId === boxId || v.mainbox_id === boxId)
    if (found && found.vehicleName) {
      return found.vehicleName
    }
    else if (found && found.vehicle) {
      return found.vehicle.baseVehicleName
    }
    else {
      return '-'
    }
  }
}

export const warningLogWithinTimePeriod = (warningLog, startTimeEpochs, endTimeEpochs) => {
  const warningStart = DateTime.fromISO(warningLog.startTime).toSeconds()

  return (
    ((startTimeEpochs >= warningStart) && (!warningLog.endTime || (startTimeEpochs <= DateTime.fromISO(warningLog.endTime).toSeconds()))) ||
    ((startTimeEpochs < warningStart) && (endTimeEpochs ? (endTimeEpochs > warningStart) : !warningLog.endTime))
  )
}

const hasWeightWarning = (startTime, endTime, warnings) => {
  return warnings?.overweightWarnings?.some(x => { return warningLogWithinTimePeriod(x, startTime, endTime)})  
}

export const vehicleHasWeightWarning = (vehicle, startTime, endTime, date) => {
  if (!vehicle?.warning_logs) return false

  return vehicle?.warning_logs[date]
    ? hasWeightWarning(startTime.toSeconds(), endTime.toSeconds(), vehicle.warning_logs[date])
    : false

}

const logHasWeightWarning = (log, warning_logs, startTime) => {
  if (!log) return false

  if (log.isPrivate || !log.boxId) {
    if (!log.boxId) console.error('Log is missing boxId! Cannot check for weight warning.', log)
    return undefined
  }

  const logStart = DateTime.fromISO(startTime ?? log.startTime).toSeconds()
  const logEnd = log.endTime ? DateTime.fromISO(log.endTime).toSeconds() : null

  const warningLogs = warning_logs?.data ?? warning_logs

  const relevantWarnings = {
    batteryGuardWarnings: warningLogs?.batteryGuardWarnings?.filter(w => w.boxId === log.boxId) ?? [],
    humidityWarnings: warningLogs?.humidityWarnings?.filter(w => w.boxId === log.boxId) ?? [],
    overweightWarnings: warningLogs?.overweightWarnings?.filter(w => w.boxId === log.boxId) ?? [],
    temperatureWarnings: warningLogs?.temperatureWarnings?.filter(w => w.boxId === log.boxId) ?? [],
  }

  return hasWeightWarning(logStart, logEnd, relevantWarnings)
}


// not used
/* export const annotateDrivingLogsWithWarnings = (driving_logs, vehicles) => {
  let warning_logs = {
    batteryGuardWarnings: [],
    overweightWarnings: []
  }
  vehicles.forEach(v => {
    if (v?.warning_logs) {
      Object.keys(v.warning_logs).map(key => {
        v.warning_logs[key].batteryGuardWarnings.forEach((log) =>{
          warning_logs.batteryGuardWarnings.push(log)
        })
        v.warning_logs[key].overweightWarnings.forEach((log) =>{
          warning_logs.overweightWarnings.push(log)
        })
      })
    }
  })

  if (!driving_logs?.data?.length) return [];

  for (let index in driving_logs.data) {
    const log = driving_logs.data[index];

    if (logHasWeightWarning(log, warning_logs)) {
      driving_logs.data[index] = {
        ...log,
        hasWeightWarning: true,
      };
    } else {
      driving_logs.data[index] = {
        ...log,
        hasWeightWarning: false,
      };
    }
  }
  return driving_logs;
} */

export const annotateVehicleRoutesWithWarnings = (vehicles, date) => {
  if (!vehicles?.length) return []

  for (const i in vehicles) {
    const v = vehicles[i]

    const warning_logs = v.warning_logs?.[date]
    const routes_per_day = v.geoJSON?.routes?.[date]
    for(const startTime in routes_per_day) {
      const log = {
        ...routes_per_day[startTime],
        boxId: v.boxId
      }
      
      if (logHasWeightWarning(log, warning_logs, startTime)) {
        routes_per_day[startTime] = {
          ...log,
          hasWeightWarning: true,
        }
      }
      else {
        routes_per_day[startTime] = {
          ...log,
          hasWeightWarning: false,
        }
      }
    }
  }
  return vehicles
}

const createStandardisedDateTime = (dateTime) => {
  return DateTime.utc(dateTime.year, dateTime.month, dateTime.day, dateTime.hour, dateTime.minute)
}
const shouldIgnoreTimezone = () => {
  return false // might want to do this at some point, when/if we implement seeing the vehicles in vehicle-time rather than user-time
}

export const RouteWithinTimeframe = (routeStartTime, routeEndTime, start, end) => {
  const ignoreTimezone = shouldIgnoreTimezone()

  let toCompare_start = ignoreTimezone ? createStandardisedDateTime(start) : start
  let toCompare_end = ignoreTimezone ? createStandardisedDateTime(end) : end
  let toCompare_routeStart = ignoreTimezone ? createStandardisedDateTime(routeStartTime) : routeStartTime
  
  return ((toCompare_routeStart >= toCompare_start && toCompare_routeStart <= toCompare_end)/*  || (routeEndTime >= start && routeEndTime <= end) */) // server doesn't filter like the whole statement (just startTime), but maybe should?
}

export const hasPositionWithinTimeframe = (vehicle, start, end, map_settings) => {
  const lastKnownPosition = getLastPosition(vehicle, map_settings)
  const ignoreTimezone = shouldIgnoreTimezone()

  let toCompare_start = ignoreTimezone ? createStandardisedDateTime(start) : start
  let toCompare_end = ignoreTimezone ? createStandardisedDateTime(end) : end
  const timestamp = DateTime.fromISO(lastKnownPosition?.timestamp ?? vehicle?.currentStatus?.timestamp)
  let toCompare_timestamp = ignoreTimezone ? createStandardisedDateTime(timestamp) : timestamp

  return toCompare_start.isValid && toCompare_end.isValid && toCompare_timestamp?.isValid && toCompare_timestamp >= toCompare_start && toCompare_timestamp <= toCompare_end
}

export const weightWarning = (percentage) => {
  return percentage >= weightWarningThreshold
}

export const weightIssue = (percentage) => {
  return percentage >= weightIssueThreshold
}

export const getWeightWarningTagColorName = (percentage) => {
  if (weightIssue(percentage)) return 'issue'
  else if (weightWarning(percentage)) return 'warning'
  else return 'primary'
}

export const getWeightWarningColor = (percentage) => {
  if (weightIssue(percentage)) return theme.colors.error
  else if (weightWarning(percentage)) return theme.colors.tag['warning']
  else return theme.colors.tag['primary']
}

const batteryIssueThreshold = 10.5
export const batteryWarning = (voltage, guard) => {
  if (voltage < guard)
    return true
  return false
}

export const batteryIssue = (voltage, guard) => {
  if (voltage < batteryIssueThreshold)
    return true
  return false
}

export const getBatteryWarningTagColorName = (voltage, batteryGuard = 11.7) => {
  if (typeof (voltage) === "undefined") {
    return false
  }

  let voltage_float = voltage.split(' ')[0]
  if (batteryIssue(voltage_float, batteryGuard)) {
    return 'issue'
  } else if (batteryWarning(voltage_float, batteryGuard)) {
    return 'warning'
  } else {
    return 'primary'
  }
}

export const getTemperatureWarningTagColorName = (celcius, warningType) => {
  if (typeof (celcius) === "undefined") {
    return false
  }
  if (warningType === 'temperature above high threshold')
    return 'issue'
  else if (warningType === 'temperature below low threshold')
    return 'libtblue'
  else
    return 'warning'  
}

export const getHumidityWarningTagColorName = (humidity, warningType) => { 
  if (typeof (humidity) === "undefined") {
    return false
  }
  if (warningType === 'humidity above high threshold')
    return 'issue'
  else if (warningType === 'humidity below low threshold')
    return 'libtblue'
  else
    return 'warning'  
}

export const getBatteryWarningColor = (voltage, batteryGuard = 11.7) => {
  if (typeof (voltage) === "undefined") {
    return false
  }

  if (batteryIssue(voltage, batteryGuard)) {
    return theme.colors.error
  } else if (batteryWarning(voltage, batteryGuard)) {
    return theme.colors.tag['warning']
  } else {
    return theme.colors.tag['primary']
  }
}

const { encode } = require('@pirxpilot/google-polyline')

export const getPositionImageUrl = (themes, geoLocation, color, height, width, zoom) =>
{
  if (!(geoLocation?.longitude || geoLocation?.longitude === 0 ) || !(geoLocation?.longitude || geoLocation?.longitude === 0)) return

  const mapStyle = 'ckay0s52d15bq1iofu8gmkrpy'
  const mapboxToken = 'pk.eyJ1Ijoic2VlcmVmaW5lIiwiYSI6ImNrYXh5OGcxYTBhdmkyeW81NGM3ODhlaXMifQ.JK353QD6jUa7g1J_RnGPVw'


  const imageRatio = themes.device === 'mobile' ? 3 / 4 : 9 / 16 // only used if no height and width are given
  const imageWidth = (height && width) ? width : themes.device === 'mobile' ? 400 : 800
  const imageHeight = (height && width) ? height : Math.floor(imageWidth * imageRatio)

  let url ='https://api.mapbox.com/styles/v1/seerefine/' + mapStyle +
        '/static/' +
        'pin-s-b+'+ color +'(' + geoLocation.longitude + ',' + geoLocation.latitude + ')' +
        '/' + geoLocation.longitude + ',' + geoLocation.latitude + ',' + zoom + '/' + 
        imageWidth + 'x' + imageHeight + '@2x?&access_token=' + mapboxToken

  return url
}


export const getRouteImageUrl = (themes, route, isOngoing, routeColor, height, width) => {
  if (!route?.length) {
    return null
  }

  const polyline = route?.map(r => [r.longitude, r.latitude]) ?? []

  const strokeWidth = themes.device === 'mobile' ? 5 : 4
  const strokeColor = routeColor
  const markerColor = strokeColor

  const imageRatio = themes.device === 'mobile' ? 3 / 4 : 9 / 16 // only used if no height and width are given
  const imageWidth = (height && width) ? width : themes.device === 'mobile' ? 400 : 800
  const imageHeight = (height && width) ? height : Math.floor(imageWidth * imageRatio)

  const mapPadding = 25

  const mapStyle = 'ckay0s52d15bq1iofu8gmkrpy'
  const mapboxToken = 'pk.eyJ1Ijoic2VlcmVmaW5lIiwiYSI6ImNrYXh5OGcxYTBhdmkyeW81NGM3ODhlaXMifQ.JK353QD6jUa7g1J_RnGPVw'

  const buildUrl = (polyline) => {
    let encodedPolyline = encode(polyline);

    return 'https://api.mapbox.com/styles/v1/seerefine/' + mapStyle +
      '/static/' +
      'path-' + strokeWidth + '+' + strokeColor + '(' + encodeURIComponent(encodedPolyline) + '),' +
      'pin-l-a+' + markerColor + '(' + polyline[0][0] + ',' + polyline[0][1] + ')' +
      (isOngoing ? '' : ',pin-l-b+' + markerColor + '(' + polyline[polyline.length - 1][0] + ',' + polyline[polyline.length - 1][1] + ')') +
      '/auto/' +
      imageWidth + 'x' + imageHeight + '@2x' +
      '?padding=' + mapPadding +
      '&access_token=' + mapboxToken
  }

  let url = buildUrl(polyline)

  if (url.length > 8192) {
    let tol = 0.001;
    let simplerLine
    const geoJson = turf.lineString([
      ...polyline,
    ]);

    while (url.length > 8192) { // character limit that mapbox will accept for the request      
      simplerLine = simplify(
        geoJson,
        { tolerance: tol, highQuality: false, mutate: true }
      ).geometry.coordinates;

      tol += .01;

      url = buildUrl(simplerLine)
    }
  }

  return url
}

export const getTemperatureWarningLevel = (vehicle, temperature, sensorId) => {
  const relevantTemperatureThresholds = vehicle?.vehicle?.climateSensorThresholds?.find(
    threshold => (threshold.sensorId === String(sensorId)) && (threshold.type === 0)
  )

  if (!relevantTemperatureThresholds) return null

  const lowerThreshold = (relevantTemperatureThresholds.low || relevantTemperatureThresholds.low === 0) ?
    relevantTemperatureThresholds.low : undefined
  const lowerWarningThreshold = (lowerThreshold || lowerThreshold === 0) ? lowerThreshold + 2 : undefined
  const higherThreshold = (relevantTemperatureThresholds.high || relevantTemperatureThresholds.high === 0) ?
    relevantTemperatureThresholds.high : undefined
  const higherWarningThreshold = (higherThreshold || higherThreshold === 0) ? higherThreshold - 2 : undefined

  if ((lowerThreshold || lowerThreshold === 0) && temperature < lowerThreshold)
    return 'issue'
  if ((lowerWarningThreshold || lowerWarningThreshold === 0) && temperature < lowerWarningThreshold)
    return 'warning'
  if ((higherThreshold || higherThreshold === 0) && temperature > higherThreshold)
    return 'issue'
  if ((higherWarningThreshold || higherWarningThreshold === 0) && temperature > higherWarningThreshold)
    return 'warning'

  return null
}

export const getHumidityWarningLevel = (vehicle, humidity, sensorId) => {
  const relevantHumidityThresholds = vehicle?.vehicle?.climateSensorThresholds?.find(
    threshold => (threshold.sensorId === String(sensorId)) && (threshold.type === 2)
  )

  if (!relevantHumidityThresholds) return null

  const lowerThreshold = (relevantHumidityThresholds.low || relevantHumidityThresholds.low === 0) ?
    relevantHumidityThresholds.low : undefined
  const lowerWarningThreshold = undefined
  const higherThreshold = (relevantHumidityThresholds.high || relevantHumidityThresholds.high === 0) ?
    relevantHumidityThresholds.high : undefined
  const higherWarningThreshold = undefined

  if ((lowerThreshold || lowerThreshold === 0) && humidity < lowerThreshold)
    return 'issue'
  if ((lowerWarningThreshold || lowerWarningThreshold === 0) && humidity < lowerWarningThreshold)
    return 'warning'
  if ((higherThreshold || higherThreshold === 0) && humidity > higherThreshold)
    return 'issue'
  if ((higherWarningThreshold || higherWarningThreshold === 0) && humidity > higherWarningThreshold)
    return 'warning'

  return null
}

export const hasClimateSensorIssue = (vehicle) => {
  if (!vehicle) return false

  let hasTemperatureIssue, hasHumidityIssue = false
  const status = vehicle.currentStatus
  const currentTemperatures = status?.thermometers ?? []

  currentTemperatures.forEach(t => {
    const relevantTemperatureThresholds = vehicle?.vehicle?.climateSensorThresholds?.find(
      threshold => (threshold.sensorId === String(t.id)) && (threshold.type === 0)
    )
    if (relevantTemperatureThresholds && (
      (relevantTemperatureThresholds.low || relevantTemperatureThresholds.low === 0) && (t.celcius < relevantTemperatureThresholds.low) 
      || 
      (relevantTemperatureThresholds.high || relevantTemperatureThresholds.high === 0) && t.celcius > relevantTemperatureThresholds.high)) {
      hasTemperatureIssue = true
    }
  })
  if (hasTemperatureIssue) return true

  if (isHumiditySensorBroken) return false

  const currentHumidities = status?.humiditySensors ?? []
  currentHumidities.forEach(h => {
    const relevantHumidityThresholds = vehicle?.vehicle?.climateSensorThresholds?.find(
      threshold => (threshold.sensorId === String(h.id)) && (threshold.type === 2)
    )
    if (relevantHumidityThresholds && (
      (relevantHumidityThresholds.low || relevantHumidityThresholds.low === 0) && h.humidity < relevantHumidityThresholds.low
      ||
      ((relevantHumidityThresholds.high || relevantHumidityThresholds.high === 0) && h.humidity > relevantHumidityThresholds.high)
    )) {
      hasHumidityIssue = true
    }
  })
  if (hasHumidityIssue) return true
  return false
}

export const hasBatteryIssue = (vehicle) => {
  if (!vehicle) return false

  const status = vehicle.currentStatus
  if (!status) return false
  return status.batteries?.some(b => {
    const guard = vehicle.vehicle.batteryGuards?.find(g => g.batteryId === b.id)
    if (!guard) return b.voltage < 11.85
    return b.voltage < guard.lowGuard || guard.highGuard && b.voltage >= guard.highGuard
  })
}

export const hasWeightIssue = (vehicle) => {
  if (!vehicle) return false
  
  const status = vehicle.currentStatus
  if (!status?.weightSystem || !vehicle.vehicle?.maxWeight || status.weightSystem.status === 255)
    return false

  const hasGrossOverweight = status?.weightSystem?.gross && vehicle.vehicle?.maxWeight?.maxGross &&
  weightWarning(status.weightSystem.gross * 100 / vehicle.vehicle.maxWeight.maxGross)
  const hasRearOverweight = status?.weightSystem?.axle2 && vehicle.vehicle?.maxWeight?.maxRear &&
  weightWarning(status.weightSystem.axle2 * 100 / vehicle.vehicle.maxWeight.maxRear)
  const hasFrontOverweight = status?.weightSystem?.axle1 && vehicle.vehicle?.maxWeight?.maxFront &&
  weightWarning(status.weightSystem.axle1 * 100 / vehicle.vehicle.maxWeight.maxFront)

  return hasGrossOverweight || hasRearOverweight || hasFrontOverweight
}

export const getVehicleBeaconStatus = (selectedVehicle, beacons) => {
  if (!selectedVehicle) return null
  var expectedBeacons = (beacons ?? []).filter(beacon => beacon?.boxId === selectedVehicle?.boxId)
  var closeBeacons = expectedBeacons.filter(beacon => beacon?.isConnected)
  return {
    expectedBeacons: expectedBeacons.length,
    closeBeacons: closeBeacons.length,
    warning: expectedBeacons.length > closeBeacons.length
  }
}

export const vehicleHasActiveWarning = (vehicle, beacons) => {
  if (!vehicle) return

  return hasBatteryIssue(vehicle) || hasClimateSensorIssue(vehicle) || (beacons && getVehicleBeaconStatus(vehicle, beacons)?.warning) || hasWeightIssue(vehicle)
}

export const vehicleMatchesMapFiltering = (vehicle, date, startTime, endTime, map_settings) => {    
  if (!vehicle) return false

  // first filter by time: has position or route within that time frame
  const hasRoutesWithinTimeframe = vehicle.geoJSON?.routes && vehicle.geoJSON.routes[date] && Object.keys(vehicle.geoJSON.routes[date]).some(startTimeISO => {
    const route = vehicle.geoJSON.routes[date][startTimeISO]
    return RouteWithinTimeframe(DateTime.fromISO(startTimeISO), DateTime.fromISO(route.endTime), startTime, endTime)
  })

  const meetsTimeCriteria = hasRoutesWithinTimeframe || hasPositionWithinTimeframe(vehicle, startTime, endTime, map_settings)

  if (!meetsTimeCriteria)
    return false

  if (!map_settings?.filter?.mustHaveWeightWarning)
    return true

  return getHasOverweight(vehicle, endTime)
}

export const dateTimeToStringKey = (dateTime) => {
  if (!dateTime || !dateTime?.isValid) return dateTime
  return dateTime.toFormat('yyyy-LLL-dd-HH-mm')

}

const getHasOverweight = (vehicle, endTime) => {
  const timeKey = dateTimeToStringKey(DateTime.fromISO(endTime))
  return timeKey && vehicle?.lastKnownPosition?.[timeKey]?.hasWeightWarning
}

const hasWeightWarningForDate = (vehicle, date, map_settings) => {
  const annotatedVehicle = annotateVehicleRoutesWithWarnings([vehicle], date)[0]

  let foundRoute = false
  if (annotatedVehicle.geoJSON?.routes) {
    if (annotatedVehicle.geoJSON.routes[date]) {
      for (let key in annotatedVehicle.geoJSON.routes[date]) {
        const route = annotatedVehicle.geoJSON.routes[date][key]
          if (route.hasWeightWarning)
            foundRoute = true
      }
    }
  }
  return foundRoute
}

// workaround
// TODO: this should be fixed on the server instead. it should not return us temperature sensors that have been removed from the box.
// interesting that this is only a problem for temperature sensors, not humidity, even though they are the same device.
const hasMatchingHumiditySensor = (vehicle, thermometer) => {
  if (!vehicle || !thermometer) return false
  return vehicle?.currentStatus?.humiditySensors?.filter(thermometer => thermometer.id != 0)?.find(hs => hs.id === thermometer.id)  ? true : false
}

export const getThermometers = (vehicle) => {
  return vehicle?.currentStatus?.thermometers?.filter(thermometer => thermometer.id != 0 && hasMatchingHumiditySensor(vehicle, thermometer)) ?? []
}
// sensors with id 0 are just the mainbox
export const getHumiditySensors = (vehicle) => {
  if (isHumiditySensorBroken) return []
  return vehicle?.currentStatus?.humiditySensors?.filter(sensor => sensor.id != 0) ?? []
}

export const formatAddressTrackers = (address) => {
  let stringBuilderAddress = ''
  let stringBuilderCodeCity = ''
  if(address?.route)
    stringBuilderAddress += ' ' + address?.route
    
  if(address?.streetNumber)
    stringBuilderAddress += ' ' + address?.streetNumber
    
  if( address?.postalCode)
    stringBuilderCodeCity += ' ' +  address?.postalCode

  if( address?.city)
    stringBuilderCodeCity += ' ' +  address?.city

  return {
    address: stringBuilderAddress,
    postCodeCity: stringBuilderCodeCity
  }
}