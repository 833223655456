import React, { useState, createContext, useContext, useEffect, Fragment } from "react";
import { connect } from 'react-redux'
import View from '@modul-connect/shared/components/atoms/view'
import theme from "@modul-connect/shared/theme";
import Card from "@modul-connect/shared/components/atoms/card/card";
import { Divider } from "@mui/material";
import { InputField } from "@modul-connect/shared/components/atoms/textInput/inputField";
import { Dropdown } from "@modul-connect/shared/components/molecules/dropdown/dropdown";
import { DateSelector } from "@modul-connect/shared/components/molecules/daySelector/daySelector";
import Button from "@modul-connect/shared/components/atoms/button/button.v2";
import { getCategoriesForDropdown, getHomebaseOptionsForDropdown } from "../../../../../utils/assets";
import { fetchMaintenancePeriodsWithDocuments, removeDocumentFromPeriod, updateAsset, updateAssetAndMaintenancePlan, updateDocumentOfPeriod, updateMaintenancePeriodComment, updateMaintenancePlan } from "../../../../../state/actions/assets";
import { useAuth0 } from '@auth0/auth0-react'
import { ModalSectionTitle, P, InfoText } from '@modul-connect/shared/components/atoms/text/text'
import { DateTime } from "luxon";
import { AddHomebase, getFrequencyOptions } from "../addMaintenanceModal";
import { dateTimeToString } from "@modul-connect/shared/utils/dateTimeUtils";
import { InfoListItem } from "@modul-connect/shared/components/molecules/listitem/infoListItem";
import { removeAssetAndMaintenancePlan } from "../../../../../state/actions/vehicles";
import { checkThatIsNumber } from "@modul-connect/shared/utils/text.js"
import DocumentDisplay from "./DocumentDisplay";
import { BubbleLoadingWidget } from '@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget'
import ConfirmationModal from "../confirmationModal";
import { ModalBase } from "@modul-connect/shared/components/molecules/modal/ModalBase";
import { ModalSection } from "@modul-connect/shared/components/molecules/modal/modalSection";
import { ImageSection } from "./assetSection/imageSection";
import DocumentSection from "./assetSection/documentSection";

const isSameMaintenanceFrequency = (newFreq, oldFreq) => {
  return (newFreq === parseInt(oldFreq) || newFreq === 0 && !oldFreq)
}

const isSameDay = (dt1, dt2) => {
  if (!dt1 && !dt2) return true
  
  if (!dt1 || !dt2) return false

  return (dt1.day === dt2.day && dt1.month === dt2.month && dt1.year === dt2.year)
}

export const AssetMaintenanceTaskContext = createContext({
  assetName: "",
  setAssetName: () => {},
  serialNumber: "",
  setSerialNumber: () => {},
  brand: "",
  setBrand: () => {},
  assetCategory: "",
  setAssetCategory: () => {},
  assetHomebaseId: "",
  setAssetHomebaseId: () => {},
  organisationId: "",
  setOrganisationId: () => {},

  images: [],
  setImages: () => {},

  maintenance_plans: [],
  
  onCancel: () => {},
  onSaveChangesToAsset: () => {},
  onSaveChangesToBoth: () => {},
  onSaveChangesToMaintenanceTask: () => {},
  onRemoveAssetAndMaintenancePlan: () => {},
  isSaving: false,
  setOpenAddOrUpdateMaintenancePeriodModal: () => {},

  serialNumberIsNumber: true,

  maintenanceTaskDueDate: undefined,
  maintenanceTaskFrequency: undefined,
  assetCreatedDate: null,

  showDeleteConfirmationModal: false,
  setShowDeleteConfirmationModal: () => {},
  
  assetImages: null,
  setAssetImages: () => {},
  serialImages: null,
  setSerialImages: () => {},
});

const MaintenanceDetailsModal = ({
  open,
  onClose,
  onSave,
  onDelete,
  onMarkasDone,
  initCreateNewHomebase,
  homebaseInput,

  maintenanceTask,
  
  maintenance_plans,
  saving,
  loading,

  updateAsset,
  updateMaintenancePlan,
  removeAssetAndMaintenancePlan,
  updateAssetAndMaintenancePlan,
  setOpenAddOrUpdateMaintenancePeriodModal,
  updateMaintenancePeriodComment,
  attachDocumentToPeriod,
  removeDocumentFromPeriod,

  openUploadImage,
  setModalForUploadingImage,
  modalForUploadingImage,
  imageUploaded,

  fetchMaintenancePeriodsWithDocuments
}) => {
  const [userHasMadeChangesToAsset, setUserHasMadeChangesToAsset] = useState(false)
  const [userHasMadeChangesToMaintenanceTask, setUserHasMadeChangesToMaintenanceTask] = useState(false)
  
  const [assetName, setAssetName] = useState('')
  const [images, setImages] = useState([])
  const [serialNumber, setSerialNumber] = useState('')
  const [brand, setBrand] = useState('')
  const [assetCategory, setAssetCategory] = useState('')
  const [assetHomebaseId, setAssetHomebaseId] = useState('')
  const [organisationId, setOrganisationId] = useState('')
  const [maintenanceTaskDueDate, setMaintenanceTaskDueDate] = useState(undefined)
  const [maintenanceTaskFrequency, setMaintenanceTaskFrequency] = useState(undefined)
  const [assetImages, setAssetImages] = useState(null)
  const [serialImages, setSerialImages] = useState(null)

  const [backupHomebaseId, setBackupHomebaseId] = useState(null)
  const [backupOrgId, setBackupOrgId] = useState(null)

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)

  const isSaving = (
    saving.updateAsset && (Object.keys(saving.updateAsset)?.length && saving.updateAsset[maintenanceTask?.assetId] === "saving") ||
    saving.updateMaintenancePlan && (Object.keys(saving.updateMaintenancePlan)?.length && saving.updateMaintenancePlan[maintenanceTask?.maintenancePlanId] === "saving")
    )
  const hasFailed = (
    saving.updateAsset && (Object.keys(saving.updateAsset)?.length && saving.updateAsset[maintenanceTask?.assetId] === "failed") ||
    saving.updateMaintenancePlan && (Object.keys(saving.updateMaintenancePlan)?.length && saving.updateMaintenancePlan[maintenanceTask?.maintenancePlanId] === "failed")
  )
  const isRemoving = saving.removeAssetAndMaintenancePlan === "saving"
  
  useEffect(() => {
    const uploadedImage = maintenance_plans?.images?.find(i => i.typeOfImage === modalForUploadingImage) 
    const imageToUpdate = images?.find(i => i.typeOfImage === modalForUploadingImage)

    if (modalForUploadingImage === 'Asset') {
      setAssetImages([{
        "oldImageId": imageToUpdate?.imageId,
        "newImageId": uploadedImage?.fileData?.imageId
      }])
    }
    if (modalForUploadingImage === 'SerialNumber') {
      setSerialImages([{
        "oldImageId": imageToUpdate?.imageId,
        "newImageId": uploadedImage?.fileData?.imageId
      }])
    }
  }, [imageUploaded, maintenance_plans?.images])

  useEffect(() => {
    if(saving.uploadImage === 'uploaded')
      setUserHasMadeChangesToAsset(true)
    else  
      setUserHasMadeChangesToAsset(false)
  }, [saving.uploadImage])

  let serialNumberIsNumber = !serialNumber || checkThatIsNumber(serialNumber)

  const backupSelectedHomebase = () => {
    setBackupHomebaseId(assetHomebaseId)
    setBackupOrgId(organisationId)
  }

  useEffect(() => {
    if (!homebaseInput) return

    if (!homebaseInput.id) {
      setOrganisationId(backupOrgId)
      setAssetHomebaseId(backupHomebaseId)

      setBackupHomebaseId(null)
      setBackupOrgId(null)

      return
    }

    setOrganisationId(homebaseInput?.organisationId)
    setAssetHomebaseId(homebaseInput?.id)
  }, [homebaseInput])

  useEffect(() => {
    if (assetHomebaseId === AddHomebase) {
      initCreateNewHomebase()
    }
  }, [assetHomebaseId])

  useEffect(() => {
    if (!isSaving && !hasFailed) {
      setUserHasMadeChangesToAsset(false)
      setUserHasMadeChangesToMaintenanceTask(false)
    }
  }, [isSaving, hasFailed])

  useEffect(() => {
    if (!maintenanceTask) return

    if (assetName !== maintenanceTask.assetName) { setUserHasMadeChangesToAsset(true); return }
    if (serialNumber !== maintenanceTask.serialNumber) { setUserHasMadeChangesToAsset(true); return }
    if (brand !== maintenanceTask.brand) { setUserHasMadeChangesToAsset(true); return }
    if (assetCategory !== maintenanceTask.category?.name) { setUserHasMadeChangesToAsset(true); return }
    if (assetHomebaseId !== maintenanceTask.homebaseId) { setUserHasMadeChangesToAsset(true); return } // homebase ID to be done

    setUserHasMadeChangesToAsset(false)
    setUserHasMadeChangesToMaintenanceTask(false)

  }, [assetName, serialNumber, brand, assetCategory, assetHomebaseId, maintenanceTask])

  useEffect(() => {
    if (!maintenanceTask) return

    const oldMaintenanceTaskDueDateDT = DateTime.fromISO(maintenanceTask?.dueDate)
    if (!isSameDay(oldMaintenanceTaskDueDateDT, maintenanceTaskDueDate)) { setUserHasMadeChangesToMaintenanceTask(true); return }
    if (!isSameMaintenanceFrequency(maintenanceTaskFrequency, maintenanceTask.daysFrequency)) { setUserHasMadeChangesToMaintenanceTask(true); return }
    
    setUserHasMadeChangesToMaintenanceTask(false)

  }, [maintenanceTaskDueDate, maintenanceTaskFrequency])

  useEffect(() => {
    if (!maintenanceTask) return

    setAssetName(maintenanceTask?.assetName)
    setImages(maintenanceTask?.images)
    setSerialNumber(maintenanceTask?.serialNumber)
    setBrand(maintenanceTask?.brand)
    setAssetCategory(maintenanceTask?.category?.name)
    setAssetHomebaseId(maintenanceTask?.homebaseId)
    setOrganisationId(maintenanceTask?.organisationId)
    setMaintenanceTaskDueDate(DateTime.fromISO(maintenanceTask?.dueDate))
    setMaintenanceTaskFrequency(maintenanceTask?.daysFrequency ? parseInt(maintenanceTask?.daysFrequency) : 0)

  }, [maintenanceTask, maintenanceTask?.images])

  const { getAccessTokenSilently } = useAuth0()

  const onRemoveAssetAndMaintenancePlan = (maintenanceTask) => {
    getAccessTokenSilently()
      .then(accessToken => 
        removeAssetAndMaintenancePlan(
          accessToken,
          maintenanceTask.assetId,
          maintenanceTask.maintenancePlanId
        )
      )
  }

  useEffect(() => {
    if(open)
      getAccessTokenSilently()
      .then(accessToken => 
        fetchMaintenancePeriodsWithDocuments(accessToken, maintenanceTask.assetId)
      )
  },[open])


  const [userInitiatedSave, setUserInitiatedSave] = useState(false)
  useEffect(() => {
    const saveFailed = userInitiatedSave && !isSaving && hasFailed
    const saveSucceeded = userInitiatedSave && !isSaving && !hasFailed

    if (saveSucceeded) {
      setUserInitiatedSave(false)
      onClose && onClose()
    }
    else if (saveFailed) {
      setUserInitiatedSave(false)
    }
  }, [isSaving, hasFailed])

  const onSaveChangesToAsset = (maintenanceTask, organisationId, assetName, category, homebaseId, brand, serialNumber, assetImages, serialIdImages) => {
    const orgIdHasChanged = organisationId !== maintenanceTask.organisationId
    const assetNameHasChanged = assetName !== maintenanceTask.assetName
    const categoryHasChanged = category !== maintenanceTask.category?.name
    const homebaseIdHasChanged = homebaseId !== maintenanceTask.homebaseId
    const brandHasChanged = brand !== maintenanceTask.brand
    if (
      maintenanceTask && maintenanceTask.assetId &&
      (organisationId || !orgIdHasChanged) && 
      (assetName || !assetNameHasChanged) && 
      (category || !categoryHasChanged) &&
      (homebaseId || !homebaseIdHasChanged) &&
      (brand || brand === !brandHasChanged))
    {
       getAccessTokenSilently()
      .then(accessToken => 
        updateAsset(
          accessToken,
          organisationId,
          assetName,
          category,
          homebaseId,
          brand,
          serialNumber,
          maintenanceTask.assetId,
          maintenanceTask,
          assetImages,
          serialIdImages
        )
      ) 
    }
  }

  const onSaveChangesToMaintenanceTask = (maintenanceTask, maintenanceTaskDueDate, maintenanceTaskFrequency) => {
    if (maintenanceTask && 
      (maintenanceTaskDueDate || isSameDay(maintenanceTaskDueDate, DateTime.fromISO(maintenanceTask.dueDate))) &&
      (maintenanceTaskFrequency || maintenanceTaskFrequency === 0 || isSameMaintenanceFrequency(maintenanceTaskFrequency, maintenanceTask?.daysFrequency))
    ) {

      const newDueDateISO = maintenanceTaskDueDate.toISO({ includeOffset: false })

      getAccessTokenSilently()
      .then(accessToken => 
        updateMaintenancePlan(
          accessToken,
          maintenanceTask.assetId,
          maintenanceTask,
          maintenanceTaskFrequency,
          newDueDateISO,
          maintenanceTask.maintenancePlanId,
          maintenanceTask.periodId
        )
      )
    }
  }

  const onSaveChangesToBoth = (
    maintenanceTask,
    organisationId,
    assetName,
    category,
    homebaseId,
    brand,
    serialNumber,
    assetImages,
    serialImages,
    maintenanceTaskDueDate,
    maintenanceTaskFrequency
  ) => {
    const orgIdHasChanged = organisationId !== maintenanceTask.organisationId
    const assetNameHasChanged = assetName !== maintenanceTask.assetName
    const categoryHasChanged = category !== maintenanceTask.category?.name
    const homebaseIdHasChanged = homebaseId !== maintenanceTask.homebaseId
    const brandHasChanged = brand !== maintenanceTask.brand
    if (
      maintenanceTask && maintenanceTask.assetId &&
      (organisationId || !orgIdHasChanged) && 
      (assetName || !assetNameHasChanged) && 
      (category || !categoryHasChanged) &&
      (homebaseId || !homebaseIdHasChanged) &&
      (brand || !brandHasChanged) &&
      (maintenanceTaskDueDate || isSameDay(maintenanceTaskDueDate, DateTime.fromISO(maintenanceTask.dueDate))) &&
      (maintenanceTaskFrequency || maintenanceTaskFrequency === 0 || isSameMaintenanceFrequency(maintenanceTaskFrequency, maintenanceTask?.daysFrequency)))
    {
      const newDueDateISO = maintenanceTaskDueDate.toISO({ includeOffset: false })
      
      getAccessTokenSilently()
      .then(accessToken => 
        updateAssetAndMaintenancePlan(
          accessToken,
          organisationId,
          assetName,
          category,
          homebaseId,
          brand,
          serialNumber,
          assetImages,
          serialImages,
          maintenanceTask.assetId,
          maintenanceTask,

          maintenanceTaskFrequency,
          newDueDateISO,
          maintenanceTask.maintenancePlanId,
          maintenanceTask.periodId
        )
      )
    }
  }

  return (
    <Fragment>
      <DeleteConfirmationModal open={showDeleteConfirmationModal} maintenanceTask={maintenanceTask} onClose={() => setShowDeleteConfirmationModal(false)} onConfirm={(toRemove) => {onRemoveAssetAndMaintenancePlan(toRemove); onDelete(toRemove)}}/>
      <ModalBase
        open={open}
        onClose={(event, reason) => () => onClose()}
        noBg
      >
        <div style={{ height: '100%', width: '100%', minWidth: '800px', display: 'flex', justifyContent: 'center', flexDirection: 'row', overflow: 'scroll' }}>
            <AssetMaintenanceTaskContext.Provider value={{
              maintenanceTask: maintenanceTask,
              maintenance_plans: maintenance_plans,
              onClose: onClose,
              onMarkasDone: onMarkasDone,

              assetId: maintenanceTask?.assetId,
              assetName: assetName,
              setAssetName: setAssetName,
              images: images,
              setImages: setImages,
              serialNumber: serialNumber,
              setSerialNumber: setSerialNumber,
              brand: brand,
              setBrand: setBrand,
              assetCategory: assetCategory,
              setAssetCategory: setAssetCategory,
              assetHomebaseId: assetHomebaseId,
              setAssetHomebaseId: setAssetHomebaseId,
              organisationId: organisationId,
              setOrganisationId: setOrganisationId,
              maintenanceTaskDueDate: maintenanceTaskDueDate,
              setMaintenanceTaskDueDate: setMaintenanceTaskDueDate,
              maintenanceTaskFrequency: maintenanceTaskFrequency,
              setMaintenanceTaskFrequency: setMaintenanceTaskFrequency,
              openUploadImage: openUploadImage,
              setModalForUploadingImage: setModalForUploadingImage,

              userHasMadeChangesToAsset: userHasMadeChangesToAsset,
              userHasMadeChangesToMaintenanceTask: userHasMadeChangesToMaintenanceTask,

              serialNumberIsNumber: serialNumberIsNumber,
              
              onSaveChangesToAsset: onSaveChangesToAsset,
              onSaveChangesToMaintenanceTask: onSaveChangesToMaintenanceTask,
              onSaveChangesToBoth: onSaveChangesToBoth,
              onRemoveAssetAndMaintenancePlan: onRemoveAssetAndMaintenancePlan,
              isSaving: isSaving,
              isRemoving: isRemoving,
              onSave: onSave,
              loading: loading,

              saving: saving,
              setUserInitiatedSave: setUserInitiatedSave,

              setOpenAddOrUpdateMaintenancePeriodModal: setOpenAddOrUpdateMaintenancePeriodModal,
              updateMaintenancePeriodComment: updateMaintenancePeriodComment,
              attachDocumentToPeriod: attachDocumentToPeriod,
              removeDocumentFromPeriod: removeDocumentFromPeriod,

              backupSelectedHomebase: backupSelectedHomebase,

              showDeleteConfirmationModal: showDeleteConfirmationModal,
              setShowDeleteConfirmationModal: setShowDeleteConfirmationModal,

              assetCreatedDate: maintenanceTask?.createdAt ? DateTime.fromISO(maintenanceTask.createdAt) : null,

              assetImages: assetImages,
              setAssetImages: setAssetImages,
              serialImages: serialImages,
              setSerialImages: setSerialImages,

              loading: loading
            }}>
              <AssetMaintenanceDetails />
            </AssetMaintenanceTaskContext.Provider>
        </div>
      </ModalBase>
    </Fragment>
  )
}


const styles = {
  container: {
    minWidth: "70%",
    height: 'fit-content',
    display: "flex",
    flexDirection: "row",
    gap: 24,
    backgroundColor: 'transparent',
    color: theme.colors.text_v2,
    padding: "12px 24px 36px 24px",
    margin: 'auto',
    overflow: 'scroll',
  }
}

const AssetMaintenanceDetails = () => {
  return (
    <View extend={styles.container}>
      <Card v2 extend={{ display: "flex", width: '100%', height: '100%', maxWidth: 1200, minWidth: 'fit-content', flexDirection: "column", padding: '36px 24px 24px 24px' }}>
          <View style={{ display: "flex", flexDirection: "row", width: '100%', height: '100%' }}>
            <View extend={{ flex: 1, display: "flex", flexDirection: "row", gap: '26px' }}>
              <View
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 26,
                  minWidth: 'fit-content',
                }}
              >
                <AssetSection />
              </View>

              <Divider orientation="vertical" flexItem />

              <View style={{
                flex: 1,
                display: 'flex',
              }}>
                <MaintenanceSection onCancel={useContext(AssetMaintenanceTaskContext).onCancel}/>
              </View>
            </View>
          </View>

          <ButtonRow />
      </Card>
    </View>
  );
};

const ButtonRow = () => {
  const context = useContext(AssetMaintenanceTaskContext)

  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false)

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end",
        gap: 10,
        marginTop: 50
      }}
    >
      <Button
        disabled={!context.userHasMadeChangesToMaintenanceTask && !context.userHasMadeChangesToAsset || context.isRemoving}
        extend={{ width: 150 }}
        processing={context.isSaving}
        onClick={() => {
          if (context.userHasMadeChangesToAsset && context.userHasMadeChangesToMaintenanceTask) {
            context.onSaveChangesToBoth(
              context.maintenanceTask,
              context.organisationId,
              context.assetName,
              context.assetCategory,
              context.assetHomebaseId,
              context.brand,
              context.serialNumber,
              context.assetImages,
              context.serialImages,
              context.maintenanceTaskDueDate,
              context.maintenanceTaskFrequency,
            )
            return
          }
          
          if (context.userHasMadeChangesToAsset) context.onSaveChangesToAsset(
            context.maintenanceTask,
            context.organisationId,
            context.assetName,
            context.assetCategory,
            context.assetHomebaseId,
            context.brand,
            context.serialNumber,
            context.assetImages,
            context.serialImages
          )

          if (context.userHasMadeChangesToMaintenanceTask) context.onSaveChangesToMaintenanceTask(
            context.maintenanceTask,
            context.maintenanceTaskDueDate,
            context.maintenanceTaskFrequency,
          )

          context.onSave && context.onSave()
          context.setUserInitiatedSave(true)
        }}
      >
        {"Save"}
      </Button>

      <ConfirmationModal v2
        open={showCancelConfirmationModal}
        onClose={() => {setShowCancelConfirmationModal(false)}}
        body={<P>You have unsaved changes. Are you sure you want to leave this page?</P>}
        onConfirm={() => {context.onClose();}}
        confirmText={'Leave'}
        cancelText={'Stay'}
      />

      <Button noBg border onClick={() => {
        const hasUnsavedChanges = context.userHasMadeChangesToMaintenanceTask || context.userHasMadeChangesToAsset
        console.log('hasUnsavedChanges', hasUnsavedChanges)
        if (hasUnsavedChanges) setShowCancelConfirmationModal(true)
        else context.onClose()
      }}>
        {"Close"}
      </Button>
    </View>
  );
}

const AssetSection = () => {
  const context = useContext(AssetMaintenanceTaskContext)
  const maintenance_plans = context.maintenance_plans

  return (
    <ModalSection>
      
        <ModalSectionTitle>{"Overview"}</ModalSectionTitle>

        <ImageSection openUploadImage={context.o}/>

        <DocumentSection />

        <View style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <InputField placeholder={"Asset name"} value={context.assetName} onChange={context.setAssetName}/>
          <InputField placeholder={"Serial Number"} value={context.serialNumber} onChange={context.setSerialNumber}/>
          <InputField placeholder={"Brand name"} value={context.brand} onChange={context.setBrand}/>
          <Dropdown placeholder={"Asset category"} value={context.assetCategory} options={getCategoriesForDropdown(maintenance_plans)} onChange={context.setAssetCategory} />
          <Dropdown placeholder={"Homebase"} value={context.assetHomebaseId} options={getHomebaseOptionsForDropdown(maintenance_plans, false, context.backupSelectedHomebase)} onChange={context.setAssetHomebaseId}/>
        </View>

        <InfoListItem noPadding v2 title={'Added'} value={dateTimeToString(context.assetCreatedDate, 'date-only')} />
        

        <Button
          noBg
          border
          disabled={context.isSaving}
          processing={context.isRemoving}
          icon={'Delete'}
          onClick={() => context.setShowDeleteConfirmationModal(true)}
          extend={{ width: 'fit-content', alignSelf: 'flex-end' }}
        >
          {"Delete Equipment"}
        </Button>        
      
    </ModalSection>
  );
};

const filterOutOngoingPeriod = (periodHistory) => {
  return periodHistory?.filter(period => period.endTime)
}

const preparePeriodHistory = (periodHistory) => {
  let toReturn = filterOutOngoingPeriod(periodHistory)?.map(period => { // we only care about completed periods, don't want the currently ongoing one
    return {
      taskPerformedOnDate: period.endTime,
      comment: period.comment,
      document: {
        file: period.document,
        fileName: period.fileName,
      },
      maintenancePlanId: period.maintenancePlanId,

      periodId: period.maintenancePeriodId,
    }
  })
  const errorIfNoVariableHere = toReturn?.sort((a, b) => {return DateTime.fromISO(a.taskPerformedOnDate) > DateTime.fromISO(b.taskPerformedOnDate) ? -1 : 1})

  return toReturn
}

const MaintenanceSection = () => {
  const context = useContext(AssetMaintenanceTaskContext)

  const [triedToMarkAsDone, setTriedToMarkAsDone] = useState(false)

  const isMarkingAsDone = context.maintenanceTask && context.saving.markMaintenanceTaskAsDone && 
    (Object.keys(context.saving.markMaintenanceTaskAsDone)?.length) && 
    context.saving.markMaintenanceTaskAsDone[context.maintenanceTask?.periodId] === 'saving'

  useEffect(() => {
    if (triedToMarkAsDone && !isMarkingAsDone) {
      // -> we are done
      // TODO: ideally, the data on the modal should update, and it should only close if the task no longer exists (because it was one-time and is now done)
      context.onMarkasDone && context.onMarkasDone()
    }
  }, [isMarkingAsDone])

  return (
    <View style={{ display: "flex", width: '100%', flexDirection: "column", gap: 16 }}>
      <ModalSection>
        <ModalSectionTitle>{"Maintenance"}</ModalSectionTitle>

        <Dropdown
          title={'Maintenance frequency'}
          options={getFrequencyOptions([])}
          value={context.maintenanceTaskFrequency}
          onChange={context.setMaintenanceTaskFrequency}
        />
        <DateSelector opensOn='bottom' title={"Due date"} date={context.maintenanceTaskDueDate} onDayPicked={context.setMaintenanceTaskDueDate} disableDaysBefore={DateTime.local()}/>

        <InfoText>{context.userHasMadeChangesToMaintenanceTask ? "Please note that your changes won't be applied until you saved them below." : ""}</InfoText>

        <View style={{ display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'flex-end', paddingTop: 24, paddingBottom: 24, alignItems: 'center' }}>
          <P noPadding>Maintenance done for the period?</P>
          <Button processing={isMarkingAsDone} onClick={() => {
            context.setOpenAddOrUpdateMaintenancePeriodModal(true)
            setTriedToMarkAsDone(true)
          }} extend={{ minWidth: 220 }}>Yes, mark as done</Button>
        </View>
    </ModalSection>

      {/* <Link onClick={() => {
        console.log('TODO: let user upload a document')
        context.setOpenAddOrUpdateMaintenancePeriodModal(true)
      }}>+ Add new document</Link> */}

      <ModalSection>
        <ModalSectionTitle>{"Maintenance history"}</ModalSectionTitle>

        {
          context.loading.fetchPeriodsWithDocs === 'loading' // handle fetching failed
          ? <BubbleLoadingWidget text={'Loading documents ... '} />
          : context.loading.fetchPeriodsWithDocs === 'failed' ? <P noPadding>Could not fetch the maintenance history from the server.</P>
          : filterOutOngoingPeriod(context.maintenanceTask?.periodHistory)?.length
            ? <MaintenanceHistoryList data={preparePeriodHistory(context.maintenanceTask?.periodHistory)}/>
            : <P noPadding>There is no maintenance history yet.</P>
        }
      </ModalSection>
    </View>
  );
};

const MaintenanceHistoryList = ({ data, maxElementsToDisplay }) => {
  const toDisplay = data?.slice(0, maxElementsToDisplay)
  const context = useContext(AssetMaintenanceTaskContext)

  const { getAccessTokenSilently } = useAuth0()

  const updateCommentWithAccessToken = (assetId, newComment, maintenancePeriodId, planId, oldComment) => {
    getAccessTokenSilently()
      .then(accessToken => {
        context.updateMaintenancePeriodComment(
          accessToken,
          assetId,
          maintenancePeriodId,
          planId,
          newComment,
          oldComment
        )
      })
  }

  const sendAttachDocument = (blobUrl, fileName, maintenancePeriodId, planId) => {
    getAccessTokenSilently()
    .then((accessToken) =>
    {
      context.attachDocumentToPeriod(accessToken, blobUrl, fileName, maintenancePeriodId, planId)
    })
  }

  const removeDocument = ( maintenancePeriodId, assetId) => {
    getAccessTokenSilently()
    .then((accessToken) =>
    {
      context.removeDocumentFromPeriod(accessToken, maintenancePeriodId, assetId)
    })
  }


  return (
    <View style={{ display: "flex", flexDirection: "column", gap: 8, maxHeight: 275, overflow: 'scroll' }}>
      {toDisplay?.map(period => <DocumentDisplay 
        key={period.periodId}
        comment={period.comment}
        commentPlaceholder={"No comment"}
        documentFileURI={period.document.file}
        documentFileName={period.document.fileName}
        date={DateTime.fromISO(period.taskPerformedOnDate)}
        isSavingComment={context.saving.updateMaintenancePeriodComment && context.saving.updateMaintenancePeriodComment[period.periodId] === 'saving'}
        isSavingDocument={
          (context.saving.updateMaintenancePeriodDocument && context.saving.updateMaintenancePeriodDocument[period.periodId] === 'saving') || 
          (context.saving.removeMaintenancePeriodDocument && context.saving.removeMaintenancePeriodDocument[period.periodId] === 'saving')
        }
        onUpdateComment={(newComment) => updateCommentWithAccessToken(context.maintenanceTask?.assetId, newComment, period.periodId, period.maintenancePlanId, period.comment)}
        onAttachDocument={(blobUrl, fileName) => sendAttachDocument(blobUrl, fileName, period.periodId, period.maintenancePlanId)}
        onRemoveDocument={(blobUrl) => removeDocument(period.periodId, context.assetId)}
      />)}
    </View>
  )

}

const DeleteConfirmationModal = ({
  open,
  maintenanceTask,
  onClose,
  onConfirm
}) => {
  return (
    <ConfirmationModal v2
      open={open}
      onClose={onClose}
      body={<P>Are you sure you want to remove this asset from all maintenance?</P>}
      onConfirm={() => {onConfirm(maintenanceTask); onClose();}}
      confirmText={'Delete'}
      confirmIcon={'Delete'}
    />
  )
}

const mapStateToProps = (props) => props;

const mapDispatchToProps = (dispatch) => ({
  updateAsset: (
    accessToken,
    organisationId,
    assetName,
    category,
    homebaseId,
    brand,
    serialNumber,
    assetId,
    oldAssetAndTask,
    assetImages,
    serialIdImages
  ) =>
    dispatch(
      updateAsset(
        accessToken,
        organisationId,
        assetName,
        category,
        homebaseId,
        brand,
        serialNumber,
        assetId,
        oldAssetAndTask,
        assetImages,
        serialIdImages
      )
    ),
  updateMaintenancePlan: (accessToken, assetId, oldMaintenancePlan, newFrequencyInDays, newDueDate, maintenancePlanId, maintenancePeriodId) => dispatch(updateMaintenancePlan(accessToken, assetId, oldMaintenancePlan, newFrequencyInDays, newDueDate, maintenancePlanId, maintenancePeriodId)),
  removeAssetAndMaintenancePlan: (accessToken, assetId, maintenancePlanId) => dispatch(removeAssetAndMaintenancePlan(accessToken, assetId, maintenancePlanId)),
  updateAssetAndMaintenancePlan: (
    accessToken, 
    organisationId,
    assetName,
    category,
    homebaseId,
    brand,
    serialNumber,
    assetImages,
    serialImages,
    assetId,
    oldAssetAndTask,

    newFrequencyInDays,
    newDueDate,
    maintenancePlanId,
    maintenancePeriodId
  ) => dispatch(updateAssetAndMaintenancePlan(accessToken, 
    organisationId,
    assetName,
    category,
    homebaseId,
    brand,
    serialNumber,
    assetImages,
    serialImages,
    assetId,
    oldAssetAndTask,

    newFrequencyInDays,
    newDueDate,
    maintenancePlanId,
    maintenancePeriodId)),
    fetchMaintenancePeriodsWithDocuments: (accessToken, assetId) => dispatch(fetchMaintenancePeriodsWithDocuments(accessToken, assetId)),
    updateMaintenancePeriodComment: (accessToken, assetId, periodId, planId, newComment, oldComment) => dispatch(updateMaintenancePeriodComment(accessToken, assetId, periodId, planId, newComment, oldComment)),
    attachDocumentToPeriod: (accessToken, documentUrl, fileName, periodId, planId) => dispatch(updateDocumentOfPeriod(accessToken, documentUrl, fileName, periodId, planId)),
    removeDocumentFromPeriod: (accessToken, periodId, assetId) => dispatch(removeDocumentFromPeriod(accessToken, periodId, assetId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceDetailsModal);