import React from "react"
import theme from "@modul-connect/shared/theme"
import { AddHomebase, OnSiteIcon, VehicleIcon, WorkshopIcon } from "../pages/reports/assetTrackerStatus/modals/addMaintenanceModal"
import Add from '@modul-connect/shared/img/add_cross.svg'
import { DateTime } from "luxon"
import { getStore } from "../state/configureStore"

export const checkTrackersBatteryIssue = (batteryPercentage) => {
  return batteryPercentage || batteryPercentage === 0 ? batteryPercentage <= 10 : false
}

export const getCategoriesForDropdown = (maintenance_plans) => {
  let plans = maintenance_plans
  if (!plans) {
    plans = getStore().getState().maintenance_plans
  }
  return plans?.categories?.length ? plans?.categories?.map(plan => {return {name: plan.name}}) : []
}


const AddIcon = <img src={Add} />
export const getHomebaseOptionsForDropdown = (maintenance_plans, useNameAsValue, onSelectAddHomebase) => {
  const options = [
    {
      name: AddHomebase,
      id: AddHomebase,
      icon: AddIcon,
      color: theme.colors.blue,
      onSelect: onSelectAddHomebase
    }
  ]

  const optionsFromMaintenancePlans = maintenance_plans?.homebases?.length ? maintenance_plans?.homebases?.map(homebase => {return {
    id: homebase.id,
    value: useNameAsValue ? (homebase.physicalVehiclesName ?? homebase.name) : homebase.id,
    name: homebase.physicalVehiclesName ?? homebase.name,
    icon: homebase.type === 'Workshop' ? WorkshopIcon : homebase.type === 'Vehicle' ? VehicleIcon : OnSiteIcon,
    type: homebase.type
    }}) : []

  // sort by homebase type, so it's less messy
  if (optionsFromMaintenancePlans.length) options.push(...optionsFromMaintenancePlans.sort((o1, o2) => ((o1.type ?? Number.MIN_VALUE) > (o2.type ?? Number.MIN_VALUE)) ? -1 : (o1.type === o2.type) ? (o1.name > o2.name ? 1 : -1) : 1))

  return options
}

// For use after updating the duedate. Later, the server should return us the correct tag in its OK message, we shouldn't need to compute this on the frontend like this.
export const getUrgencyTagsFromDueDateISO = (dueDateISO) => {
  const now = DateTime.local().startOf('day')
  const dueDate = DateTime.fromISO(dueDateISO).startOf('day')

  const differenceInDays = dueDate.diff(now, 'days').toObject().days

  return {
    isOverdue: differenceInDays < 0,
    isInOneWeek: differenceInDays >= 0 && differenceInDays <= 7,
    isInThreeWeeks: differenceInDays > 7 && differenceInDays <= 21
    
  }
}