import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux'
import Button from "@modul-connect/shared/components/atoms/button/button.v2";
import View from '@modul-connect/shared/components/atoms/view'
import Vehicle from '@modul-connect/shared/img/vehicle.svg'
import Workshop from '@modul-connect/shared/img/workshop.svg'
import OnSite from '@modul-connect/shared/img/on_site.svg'
import UncheckedBox from '@modul-connect/shared/img/small_unchecked_box.svg'
import CheckedBox from '@modul-connect/shared/img/check-box-ok.svg'
import theme from '@modul-connect/shared/theme';
import { createNewHomebase } from "../../../../state/actions/vehicles";
import { useAuth0 } from "@auth0/auth0-react";
import { InputField } from '@modul-connect/shared/components/atoms/textInput/inputField'
import { Dropdown, Title } from '@modul-connect/shared/components/molecules/dropdown/dropdown'
import { ModalBase } from "@modul-connect/shared/components/molecules/modal/ModalBase";

export const homeBaseType = {
  Workshop: "Workshop",
  Vehicle: "Vehicle",
  OnSite: "OnSite"
}

const AddHomebaseModal = ({
  open,
  onClose,
  onSave,
  setHomebaseInput,
  maintenance_plans,
  createNewHomebase,

  saving
}) => {
  const [homebase, setHomebase] = useState('')
  const [homebaseNameExistsAlready, setHomebaseNameExistsAlready] = useState(false)
  const [vehicleIconSelected, setVehicleIconSelected] = useState(false)
  const [workshopIconSelected, setWorkshopIconSelected] = useState(false)
  const [onSiteIconSelected, setOnSiteIconSelected] = useState(false)
  const [organisation, setOrganisation] = useState('')

  const [waitForServerReponse, setWaitForServerResponse] = useState(false)


  function usePreviousValue(value) {
    const ref = useRef(undefined);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }


  const wasSaving = usePreviousValue(saving.newHomebase) === "saving";
  useEffect(() => {
    if (wasSaving && waitForServerReponse && !saving.newHomebase) {
      const newHomebase = maintenance_plans?.homebases.find(hb => hb.name === waitForServerReponse)
      if (newHomebase) setHomebaseInput( // this is not going to work, need to wait for server response so we have the homebaseId
        { homebaseName: newHomebase.name, organisationId: newHomebase.organisationId, type: newHomebase.type, id: newHomebase.id })

      setWaitForServerResponse(false)
    }
  }, [saving.newHomebase])

  useEffect(() => {
    setHomebase('')
    setOrganisation('')
    setVehicleIconSelected(false)
    setWorkshopIconSelected(false)
    setOnSiteIconSelected(false)
  }, [open])

  const disabled = homebase.trim() === '' || homebaseNameExistsAlready || organisation.trim === '' ||  (!vehicleIconSelected && !workshopIconSelected && !onSiteIconSelected)

  const { getAccessTokenSilently } = useAuth0();

  const getTypeOfHomeBase = () => {
    let homeType = homeBaseType.OnSite
    if (vehicleIconSelected)
      homeType = homeBaseType.Vehicle
    if (workshopIconSelected)
      homeType = homeBaseType.Workshop

    return homeType
  }
  const sendNewHomebaseCreation = () => {

    getAccessTokenSilently().then((accessToken) =>
      createNewHomebase(accessToken, organisation?.replace('organisation/', ''), homebase.trim(), getTypeOfHomeBase())
    )
  }

  useEffect(() => {
    const nameAlreadyExists = maintenance_plans?.homebases?.some(hb => hb.name.toLowerCase() === homebase.trim().toLocaleLowerCase())

    if (nameAlreadyExists) {
      setHomebaseNameExistsAlready(true)
      return
    }
    else setHomebaseNameExistsAlready(false)
  }, [homebase])

  return (
    <ModalBase onClose={onClose} open={open} extend={{ width: 'auto' }} dropShadow>
      <View style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>

      <InputField
        placeholder={'Name of homebase'}
        label={'Name of homebase'}
        value={homebase}
        onChange={setHomebase}
        isRequired
        error={homebaseNameExistsAlready}
        helperText={homebaseNameExistsAlready ? "Name exists already" : null}
      />

      <Dropdown
        options={maintenance_plans?.organisations?.map(organisation => { return {
          id: organisation.organisationId,
          value: organisation.organisationId,
          name: organisation.organisationName
        }})}
        placeholder={'Select organisation'}
        value={organisation}
        onChange={setOrganisation}
        isRequired />

      <View>
        <Title>
          {'* Select icon for homebase'}
        </Title>

          <View style={{ border: `1px solid ${theme.colors.uiBorderColor}`, borderRadius: 4 }}>
            <CustomHomebaseSelector
              vehicleIconSelected={vehicleIconSelected}
              setVehicleIconSelected={setVehicleIconSelected}
              workshopIconSelected={workshopIconSelected}
              setWorkshopIconSelected={setWorkshopIconSelected}
              onSiteIconSelected={onSiteIconSelected}
              setOnSiteIconSelected={setOnSiteIconSelected}
              allowOneSelection
            />
          </View>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', fontSize: 12 }}>
          * Required fields
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }}>
          <Button
            extend={{ width: '30%' }}
            disabled={disabled}
            onClick={() => {
              onSave(); {
                sendNewHomebaseCreation(); 
                setWaitForServerResponse(homebase)
              }
            }}
          >
            {'Save'}
          </Button>

          <Button
            border
            noBg
            onClick={() => {onClose(); setHomebaseInput({id: null})}}
          >
            {'Cancel'}
          </Button>
        </View>
      </View>
    </ModalBase>
  )
}

export const CustomHomebaseSelector = ({
  vehicleIconSelected,
  setVehicleIconSelected,
  workshopIconSelected,
  setWorkshopIconSelected,
  onSiteIconSelected,
  setOnSiteIconSelected,
  onEnd,
  fullWidth,
  allowOneSelection
}) => {
  const VehicleIcon = <img src={Vehicle} />
  const OrganisationIcon = <img src={Workshop} />
  const OnSiteIcon = <img src={OnSite} />
  const UncheckedBoxIcon = <img src={UncheckedBox} />
  const CheckedBoxIcon = <img src={CheckedBox} />

  return (
    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: onEnd ? 'flex-end' : 'flex-start', width: fullWidth ? '100%' : 'auto', gap: 20, padding: 5 }}>

      <View style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
        <View onClick={() => {
          if (allowOneSelection) {
            setVehicleIconSelected(true);
            setWorkshopIconSelected(false);
            setOnSiteIconSelected(false)
          }
          else {
            setVehicleIconSelected(!vehicleIconSelected);
          }
        }}>
          {vehicleIconSelected ? CheckedBoxIcon : UncheckedBoxIcon}
        </View>
        <View>
          {VehicleIcon}
        </View>
      </View>

      <View style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }} >
        <View onClick={() => {
          if (allowOneSelection) {
            setVehicleIconSelected(false);
            setWorkshopIconSelected(true);
            setOnSiteIconSelected(false)
          }
          else {
            setWorkshopIconSelected(!workshopIconSelected);
          }
        }} >
          {workshopIconSelected ? CheckedBoxIcon : UncheckedBoxIcon}
        </View>
        <View>
          {OrganisationIcon}
        </View>
      </View>

      <View style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
        <View onClick={() => {
          if (allowOneSelection) {
            setVehicleIconSelected(false);
            setWorkshopIconSelected(false);
            setOnSiteIconSelected(true)
          }
          else {
            setOnSiteIconSelected(!onSiteIconSelected);
          }
        }}>
          {onSiteIconSelected ? CheckedBoxIcon : UncheckedBoxIcon}
        </View>
        <View>
          {OnSiteIcon}
        </View>
      </View>
    </View>
  )
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
  createNewHomebase: (accessToken, organisationId, homebaseName, homebaseType) =>
    dispatch(createNewHomebase(accessToken, organisationId, homebaseName, homebaseType))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddHomebaseModal);